/*For About me page: 
    template render
    about me personally
    artist entrance
    delight of food
    --> travel the world will be in its own file
*/
import React from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import {matchID} from '../../content/Aboutmeinfo';
import {ImgLeft,ImgRight} from '../CardElement/AboutMe_nobtn';
// import ImgRight from '../../components/CardElement/ImgRight';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import BreadCrumb from '../../components/Breadcrumb';
import AboutMeTravel from './AboutMeTravel';
const Styles = styled.div`

    .section{
        margin-bottom: 10rem;
    }
    a:hover{
        text-decoration: none;
    }
    .btn-style{
        margin-top: 8rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-item: center;
    }
    .btn-primary{
        position: absolute;
        border-radius: 0px;
        background-color: #000;
        border: none;
        width: 160px;
        height: 40px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        z-index:1;
    }
    
    .borderline{
        border: 2px solid #000;
        width: 160px;
        height: 40px;
        z-index:0;
        position: absolute;
        margin-top: 0.5%;
        margin-left: 1%;
    }
    @media only screen and (max-width: 800px) {
        .borderline{
            border: 2px solid #000;
            width: 160px;
            height: 40px;
            z-index:0;
            position: absolute;
            margin-top: 1.5%;
            margin-left: 3%;
        }
    }

`;


export default function AboutMe(props) {
    return(
    <Styles>
        <FontStyle>
            <React.Fragment>
                {/* <div className="spacing-block"/> */}
                <br/><br/><br/><br/>
                <Container>
                    <Row>
                        <BreadCrumb 
                            link1={'/'} link2 ={'/about'} secondlevel={'About Me'}
                            current={props.match.params.title}
                        />
                    </Row>
                    
                    <h2>{props.match.params.title}</h2>
                    <Row key={props.match.params.id}>
                        {/* {props.match.params.id} == 3 */}

                        {props.match.params.id !== '3' ? 
                            matchID[props.match.params.id].map((ele,index) => (
                                index % 2 === 0 ?
                                <ImgLeft
                                    imgsrc_regular = {ele.imgsrc_regular}
                                    description = {ele.description}
                                    bold_description = {ele.bold_description}
                            />
                                :<ImgRight
                                        imgsrc_regular = {ele.imgsrc_regular}
                                        description = {ele.description}
                                        bold_description = {ele.bold_description}
                                />
                                ))
                            : <AboutMeTravel/>      
                        } 
                    </Row> 
                </Container>
    
                <div className="btn-style">
                    <Button href='/about'>Back to About Me</Button>
                    <div className="borderline"/>
                </div>

                   
                <div className="section"/>
                   
               
            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}

