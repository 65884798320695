/*the background image & headshot of home page */
import React,{useState, useEffect} from 'react';
import { Jumbotron as Jumbo, Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import styled from 'styled-components';
import {Travel,TravelImg} from '../../../content/Aboutmeinfo';
import FontStyle from '../../../FontStyles';
import BreadCrumb from '../../../components/Breadcrumb';

//import image
import BubblesTravel0 from "../../../a_media/about_img/bubblesdesign/z_subpage_travel_0.png";
import Greenbar from "../../../a_media/about_img/bubblesdesign/greenbar.png";

const Style = styled.div`
h3{
//   margin-top: 5rem;
  margin-bottom:2rem;
  color: #fff;
}
p{
  color: #fff;
  font-weight: 400;
  line-height: 22px;
}
.row{
    margin-left:0;
    margin-right:0;
}

i{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color:#fff;
}

.greenble{
    width:15%;
    position:absolute;
    z-index:0;
    top:130px;
    right:230px;
}
.sidecolor{
    position: fixed;
    width: 1.3%;
    bottom:0px;
    left: 0px;
    top: 100px;
    background: linear-gradient(180deg, #000000 0%, #3B9B62 9.9%, rgba(152, 217, 87, 0.38) 85.42%, #000000 98.44%);
    transform: rotate(0deg);
}


//button css
.btn-style{
  position: relative;
}
.btn-primary{
  position: absolute;
  border-radius: 0px;
  background-color: #B30009;
  border: none;
  width: 100px;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  z-index:1;
}

.borderline{
  border: 2px solid #FFFFFF;
  width: 100px;
  height: 40px;
  z-index:0;
  position: absolute;
  margin-top: 3%;
  margin-left: 3%;
}

.pre-btn{
    position: relative;
}
.pre-btn .borderline{
    margin-top: 2%;
    margin-left: 2%;
}
.next-btn {
    position: relative;
    left:65%;
}


//breadcrumb style
.breadcrumb{
    background-color: #000000 !important;
}
.breadcrumb-item a{
    color: #FD3A44;
}
.breadcrumb-item.active{
    color: #fff !important;
}


//spacing
.spacing{
    margin-top:8rem;
}
.btn-spacing{
    margin-top:10rem;
    margin-bottom:2rem;
}


//btn text
.navi-text i{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#c4c4c4;
}
.navi-text{
    margin-top:4rem;
}

//images grid layout
.gallery{
    padding-left:0.5rem;
    padding-right:0.5rem;
    width:100%;
    margin-bottom: 1rem;
}


@media only screen and (max-width: 800px) {
    .img-section{
        padding-bottom:1rem;
    }
    img{
        width:100%;
    }
    .spacing{
        margin-top:3rem;
    }
    .btn-spacing{
        margin-top:5rem;
        margin-bottom:2rem;
    }
    .container{
        padding-left:0;
        padding-right:0;
    }
    .next-btn {
        position: relative;
        left:28%;
    }
    .greenble{
        width:50%;
        position:absolute;
        z-index:0;
        top:380px;
        right:2px;
    }
}

`;



export default function TravelWorld(props) {
    return (
        <React.Fragment>
         
          <Container fluid style={{backgroundColor: "#000000", paddingBottom:"20vh", top: "0",width:"100vw", zIndex:"1"}}>
            <Style>
              <FontStyle>
              <div className="sidecolor"></div>
                <Container>
                    <br/><br/> <br/> <br/>
                    <Row>
                        <BreadCrumb 
                            link1={'/'} link2 ={'/about'} secondlevel={'About Me'}
                            current={Travel[0].title}
                        />
                    </Row>
                    <img className="greenble" src={BubblesTravel0}/>
                    
                    <Row style={{marginTop:'3rem'}}>
                        <Col xs={12} md={6}>
                            <div className="img-section">
                                <img src={Travel[0].imgsrc_bubbles} width="100%"/>
                            </div>
                        </Col>
                        <Col xs={12} md={{offset:1, span:4}}>
                            <h3>{Travel[0].title}</h3>
                            <p>{Travel[0].description}</p>
                        </Col>
                    </Row>
                   
                    <Row className="spacing">
                        {TravelImg.map((ele) => {
                            return(
                                <Col className="gallery" xs={12} md={4} >
                                    <img src={ele.imgsrc_regular} alt={ele.title} width="100%"/>
                                </Col>
                            );
                        })}
                    </Row>
                    

                <Row className="btn-spacing">
                    {/* <div className="btn-section"> */}
                        <Col xs={6} md={6}>
                            {/* <div className=""> */}
                                <div className="pre-btn btn-style">
                                    <Button href={'/about'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="40" y1="12" x2="5" y2="12"></line>
                                            <polyline points="12 19 5 12 12 5"></polyline>
                                        </svg> &nbsp;&nbsp;&nbsp;
                                        Back
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div> */}
                                <div className="navi-text">
                                    <i>to About Me</i>
                                </div>
                            
                        </Col>
                        <Col xs={6} md={{offset:2, span:4}}>
                            {/* <div > */}
                            {/* <div className=""> */}
                                <div className="next-btn btn-style">
                                    <Button href={'/about/fooddelight'}>
                                        Next &nbsp;&nbsp;&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="0" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div>     */}
                                <div style={{textAlign:'right'}} className="navi-text">
                                    <i>to The Delight of Food</i>
                                </div>
                            {/* </div> */}
                      
                        </Col>
                        {/* </div> */}
                </Row> 

                </Container>

               
              </FontStyle> 
            </Style>
          </Container>
        </React.Fragment>
    );
}