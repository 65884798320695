/*For member card in the Lab*/
import React from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';

const Styles = styled.div`
  .card{
    margin-top: 1em;
  }
  // .card-img-top{
  //   border-radius: 2px;
  // }
  img{
    border-radius: 0px;
    margin-bottom: 1em;
    filter: grayscale(100%);
  }
  img:hover {
    filter: none;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
 }
  .card-body{
    padding:0px !important;
    color: #000;
  }
  .card-body a:hover{
    text-decoration: none;
  }
`;



function CardMember(props){
  return(
    <Styles>
      <FontStyle>
        <Card border="light">
          <Card.Img variant="top" src={props.imgsrc} />
          <Card.Body>
            <Card.Title>{props.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{props.major} | {props.year}</Card.Subtitle>
            {/* <Card.Text>
              Major | Year
            </Card.Text> */}
          </Card.Body>
        </Card>
      </FontStyle>
    </Styles>
  );
}

export default CardMember;
