import React from 'react';

// import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './Home';
import { Research } from './Research';
import { Lab } from './Lab';
import { Publications }  from './Publications';
import { Teaching } from './Teaching';
import Speaker_Consultant from './Speaker_Consultant';
import { NoMatch } from './NoMatch';
// import { Layout } from './Layout';

import Navibar from './components/Navibar';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import SubpageCurrently from './components/CardElement/Home_subpages_currentlyworking';

// import NavibarScratch from './components/Navibar_FromScratch';
// import Googlemap from './components/Googlemap';

//subpages in general
import MemberPage from './components/DisplayPage/MemberPage';
import CoursePage from './components/DisplayPage/CoursePage';
import ResearchPage from './components/DisplayPage/ResearchPage';

//about (carole_V1)
import AboutWithBubbles from './components/DisplayPage/AboutWbubbles';
//about (carole_V2)
import AboutWithoutBubbles from './components/DisplayPage/AboutNbubbles';
//about (previous design_V1)
import { AboutRegular } from './components/DisplayPage/AboutRegular';
//random picker for about me landing
import About from './About';


//random thoughts for both
import {AboutWorkFor} from './content/aboutmesecondary/AboutWorkFor';
import AboutVideo from './content/aboutmesecondary/AboutVideo';

//subpages for previous design
import AboutMe from './components/DisplayPage/AboutMe';

//subpages for carole's design
import AboutMePersonally from './components/DisplayPage/Bubbles_AboutSubpages/AboutMePersonally';
import Artist from './components/DisplayPage/Bubbles_AboutSubpages/ArtistEntrance';
import TravelWorld from './components/DisplayPage/Bubbles_AboutSubpages/Travel';
import FoodDelight from './components/DisplayPage/Bubbles_AboutSubpages/Food';

export const ThemeContext = React.createContext(null);



function App (){

    return(
      <React.Fragment>
        <Router>
        <Navibar/>
        <ScrollToTop/>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/currentlysubpage" component={SubpageCurrently} />

              <Route exact path="/research" component={Research} />
              <Route exact path="/research/:title" component={ResearchPage} />


              <Route exact path="/teaching" component={Teaching} />
              <Route exact path="/teaching/:level/:id/:coursecode" component={CoursePage}/>
  
              <Route exact path="/lab" component={Lab} />
              <Route exact path="/lab/:level/:id/:name" component={MemberPage}/>
              
              <Route exact path={"/about"} component={About} />
              
              <Route exact path="/about/wff" component={AboutWorkFor}/>
              <Route exact path="/about/videogame" component={AboutVideo}/>

              <Route exact path="/about/:id/:title" component={AboutMe}/>

              <Route exact path="/about/aboutpersonally" component={AboutMePersonally}/>
              <Route exact path="/about/artistentrance" component={Artist}/>
              <Route exact path="/about/travelworld" component={TravelWorld}/>
              <Route exact path="/about/fooddelight" component={FoodDelight}/>

              <Route exact path="/publications" component={Publications} />
              <Route exact path="/publications/:currentID" component={Publications} />
             
              <Route exact path="/speaker_consultant" component={Speaker_Consultant} />
              

              <Route component={NoMatch}/>
            </Switch>   
        </Router>
        <Footer/>
      
      </React.Fragment>
      
    );
  }

export default App;


