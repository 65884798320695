/*For About me page: 

 --> travel the world will be in its own file
*/
import React from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import {Travel, TravelImg} from '../../content/Aboutmeinfo';
import {ImgLeft} from '../CardElement/AboutMe_nobtn';
// import ImgRight from '../../components/CardElement/ImgRight';
import { Container, Row, Col } from 'react-bootstrap';

import BreadCrumb from '../Breadcrumb';

const Styles = styled.div`
    .col-md-4{
        padding:0.5rem;
        
    }
    .container{
        padding-left:0rem;
        padding-right:0rem;
    }
    .img-section{
        margin-top:5rem;
    }
`;


export default function AboutMeTravel(props) {

  return(
    <Styles>
        <FontStyle>
            <React.Fragment>
                <Container>
                    <Row key={props.id}>
                        <h2>{props.title}</h2>
                        {Travel.map((ele)=>{
                            return(
                                <ImgLeft
                                imgsrc_regular = {ele.imgsrc_regular}
                                description = {ele.description}
                            /> 
                            );
                        })}   

                        <div className="img-section"/>
                        <Row>
                            {TravelImg.map((ele) => {
                                return(
                                    <Col xs={12} md={4} >
                                        <img src={ele.imgsrc_regular} alt={ele.title} width="100%"/>
                                    </Col>
                        
                                );
                            })}
                       </Row>
                    </Row>
                </Container>
            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}

