/*the background image & headshot of home page */
import React,{useState, useEffect} from 'react';
import { Jumbotron as Jumbo, Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import styled from 'styled-components';
import {Aboutmeinfo} from '../../content/Aboutmeinfo';
import FontStyle from '../../FontStyles';

//import image

import AboutMe from '../../a_media/about_img/bubblesdesign/aboutme_img.png';
import ArtistEntrance from '../../a_media/about_img/bubblesdesign/artistentrance_img.png';
import Travel from '../../a_media/about_img/bubblesdesign/travelworld_img.png';
import Food from '../../a_media/about_img/bubblesdesign/food_img.png';
import MArtistEntrance from '../../a_media/about_img/bubblesdesign/mobile_artistentrance_img.png';
import MTravel from '../../a_media/about_img/bubblesdesign/mobile_travelworld_img.png';
import MFood from '../../a_media/about_img/bubblesdesign/mobile_food_img.png';


const Styles = styled.div`
h3{
  margin-top: 3rem;
  color: #fff;
}
p{
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 22px;
}
// img{
//   filter:saturate(1);
// }

//button css
.btn-primary{
  position: absolute;
  border-radius: 0px;
  background-color: #B30009;
  border: none;
  width: 160px;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  z-index:1;
}
.btn-style{
  position: relative;
}

.borderline{
  border: 2px solid #FFFFFF;
  width: 160px;
  height: 40px;
  z-index:0;
  position: absolute;
  margin-top: 2.5%;
  margin-left: 2%;
}

//text section for about me personally
.text-section{
  margin-top: 6rem;
}

//desktop style
.content{
  padding-left: 24px;
  top: 0;
}

//first section: about me personally
.firstsection{
  margin-top: 3%;
  margin-bottom: 15%;
  // margin-left: 3%;
}
.desktop-firstB{
  position: absolute;
  left:0;
}

//2 section: artist entrance
.secondsection{
  position: relative;
  margin-top: 25%;
}
.desktop-secondB{
  position: absolute;
  right:0;
  margin-top: 70%;
}

//3 section: travel the world
.thirdsection{
  position: relative;
  margin-top: 40%;
}
.desktop-thirdB{
  position: absolute;
  left:0;
  margin-top: 105%;
}

//4 section: delight of food
.fourthsection{
  position: relative;
  margin-top: 35%;
}
.desktop-fourthB{
  position: absolute;
  right:0;
  margin-top: 155%;
}


.randomthought{
  position: relative;
  margin-top: 40%;
}

.randomthought-content{
  margin-top:8%;
}
.randomthought_bg{
  position: absolute;
  margin-top: 10%;
  width: 508px;
  height: 182px;
  background: #272446;
  filter: blur(80px);
}
.center{
  position: relative;
  text-align: center;
  margin-top: 15%;
}

`;

 function DesktopComponent() {
  return (
    
    <React.Fragment>
      <br/><br/><br/>
      <Container fluid style={{backgroundColor: "#000000", paddingBottom:"20vh", top: "0",width:"100vw", zIndex:"1"}}>
        <Styles>
          <FontStyle>
          {/* <img className="desktop-firstB" src={FirstB} width="13%"/>
          <img className="desktop-secondB" src={SecondB} width="13%"/>
          <img className="desktop-thirdB" src={ThirdB} width="13%"/>
          <img className="desktop-fourthB" src={FourthB} width="13%"/> */}
            <br/><br/><br/>
            
            <div className="content">
              <Container>
                {/* first section: about me personally */}
                <Row className="firstsection">               
                  <Col md={6} style={{maxWidth:"47%"}}>
                    <div className="text-section">
                      <h3>{Aboutmeinfo[0].title}</h3>
                      <p>{Aboutmeinfo[0].description}</p>
                        <div className="btn-style">
                          <Button href={'/about/aboutpersonally'}>Learn more</Button>
                          <div className="borderline"/>
                        </div>
                      </div>
                  </Col>
                  <Col md={{span:6}} style={{marginLeft: "3%", marginTop:"-3%"}}>
                    <img src={AboutMe} width="110%"/>
                  </Col>
                </Row>


                {/* second section: through the artist's entrance */}
                <Row className="secondsection">
                  <img style={{position:"absolute", marginTop:"-11%"}} src={ArtistEntrance} width="100%"/>
                  <Col md={{offset: 6, span: 5}} style={{maxWidth:"36%"}}>
                    <h3>{Aboutmeinfo[1].title}</h3>
                    <p>{Aboutmeinfo[1].description}</p>
                      <div className="btn-style">
                        <Button href={'/about/artistentrance'}>Learn More</Button>
                        <div className="borderline"/>
                      </div>
                  </Col>
                </Row>


                {/* third section: travel the world */}
                <Row className="thirdsection">               
                  <Col md={{span:6}} style={{maxWidth:"47%"}}>
                    <div className="text-section">
                      <h3>{Aboutmeinfo[2].title}</h3>
                      <p>{Aboutmeinfo[2].description}</p>
                        <div className="btn-style">
                          <Button href={'/about/travelworld'}>Learn More</Button>
                          <div className="borderline"/>
                        </div>
                      </div>
                  </Col>
                  <Col md={{span:6}} style={{marginTop:"-3%"}}>
                    <img src={Travel} width="120%"/>
                  </Col>
                </Row>



                {/* fourth section: food */}
                <Row className="fourthsection">
                  <img style={{position:"absolute", marginTop:"-20%"}} src={Food} width="100%"/>
                  <Col md={{offset: 6, span: 5}} style={{maxWidth:"35%"}}>
                    <h3>{Aboutmeinfo[3].title}</h3>
                    <p>{Aboutmeinfo[3].description}</p>
                      <div className="btn-style">
                        <Button href={'/about/fooddelight'}>Learn More</Button>
                        <div className="borderline"/>
                      </div>
                  </Col>
                </Row>

               
                <div className="randomthought">
                  <h3>Random thoughts</h3>
                  <Row>
                    <Col md={6}>
                      <div className="randomthought_bg"/>
                    </Col>
                    <Col md={6}>
                      <div className="randomthought_bg"/>
                    </Col>
                  </Row>

                  <Row className="randomthought-content">
                    <Col md={{offset:1, span:5}}>
                      <p>Working for Money vs. Fun vs. Meaning</p>
                      <div className="btn-style">
                        <Button href="/about/wff">Learn More</Button>
                        <div className="borderline"/>
                      </div>
                    </Col> 
                    <Col md={{offset:1, span:5}}>
                      <p>Are We Living in a 3D Video Game?</p>
                      <div className="btn-style">
                        <Button href="/about/videogame">Learn More</Button>
                        <div className="borderline"/>
                      </div>
                    </Col> 
                  </Row>
                </div>

                <div className="center">
                  <p>More Coming Soon...</p>
                </div>


              </Container>
            </div>

          </FontStyle> 
        </Styles>
      </Container>
    </React.Fragment>
   
  )
}

const MobileStyle = styled.div`
h3{
  margin-top: 2rem;
  color: #fff;
}
p{
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 22px;
}


//button css
.btn-style{
  position: relative;
}
.btn-primary{
  position: absolute;
  border-radius: 0px;
  background-color: #B30009;
  border: none;
  width: 160px;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  z-index:1;
}

.borderline{
  border: 2px solid #FFFFFF;
  width: 160px;
  height: 40px;
  z-index:0;
  position: absolute;
  margin-top: 3%;
  margin-left: 3%;
}


//1 section: about me personally
.firstsection{
  position: relative;
  margin-top: 18%;
  margin-bottom: 15%;
}
.mobile-firstB{
  position: absolute;
  left:0;
  margin-top: 25%;
}

//2 section: artist entrance
.secondsection{
  position: relative;
  margin-top: 60%;
}
.mobile-secondB{
  position: absolute;
  right:0;
  margin-top: 200%;
}

//3 section: travel the world
.thirdsection{
  position: relative;
  margin-top: 60%;
}
.mobile-thirdB{
  position: absolute;
  left:0;
  margin-top: 400%;
}

//4 section: delight of food
.fourthsection{
  position: relative;
  margin-top: 60%;
}
.mobile-fourthB{
  position: absolute;
  right:0;
  margin-top: 690%;
}

.randomthought{
  position: relative;
  margin-top: 60%;
}

.randomthought-content{
  position: relative;
  height: 150px;
}
.randomthought_bg{
  position: absolute;
  width: 320px;
  height: 100px;
  background: #272446;
  filter: blur(20px);
}
.center{
  position: relative;
  text-align: center;
  margin-top: 15%;
}
`;

function MobileComponent() {
  return (
    <React.Fragment>
      <Container fluid style={{backgroundColor: "#111010", paddingBottom:"20vh", top: "0",width:"100vw", zIndex:"1"}}>
        <MobileStyle>
          <FontStyle>
            {/* <img className="mobile-firstB" src={MfirstB} width="35%"/>
            <img className="mobile-secondB" src={SecondB} width="25%"/>
            <img className="mobile-thirdB" src={ThirdB} width="30%"/>
            <img className="mobile-fourthB" src={FourthB} width="25%"/> */}
            <br/><br/><br/>
             <div className="content">
              {/* <Container> */}
                {/* first section: about me personally */}
                <div className="firstsection">   
                  <Col sm={12} style={{marginTop:"3%"}}>
                    <img style={{marginLeft:"8%"}} src={AboutMe} width="90%"/>
                  </Col>            
                  <Col sm={12}>
                    <div className="text-section">
                      <h3>{Aboutmeinfo[0].title}</h3>
                      <p>{Aboutmeinfo[0].description}</p>
                        <div className="btn-style">
                          <Button href={'/about/aboutpersonally'}>More About Me</Button>
                          <div className="borderline"/>
                        </div>
                      </div>
                  </Col>
                 
                </div>


                {/* second section: through the artist's entrance */}
                <div className="secondsection">
                  <Col sm={12}>
                    <img src={MArtistEntrance} width="100%"/>
                  </Col>
                  <Col sm={12}>
                    <h3>{Aboutmeinfo[1].title}</h3>
                    <p>{Aboutmeinfo[1].description}</p>
                      <div className="btn-style">
                        <Button href={'/about/artistentrance'}>Learn More</Button>
                        <div className="borderline"/>
                      </div>
                  </Col>
                </div>

                {/* third section: travel the world */}
                <div className="thirdsection">    
                  <Col sm={12}>
                    <img style={{marginLeft:"5%"}} src={MTravel} width="100%"/>
                  </Col>           
                  <Col sm={12}>
                    <div className="text-section">
                      <h3>{Aboutmeinfo[2].title}</h3>
                      <p>{Aboutmeinfo[2].description}</p>
                        <div className="btn-style">
                          <Button href={'/about/travelworld'}>Learn More</Button>
                          <div className="borderline"/>
                        </div>
                      </div>
                  </Col> 
                </div>

                {/* fourth section: food */}
                <div className="fourthsection">
                  <Col sm={12}>
                    <img src={MFood} width="100%"/>
                  </Col>
                  <Col sm={12}>
                    <div className="text-section">
                      <h3>{Aboutmeinfo[3].title}</h3>
                      <p>Why should the delight one takes be more tied to primitive sensing than 
                        to interpretation based on knowledge, history and context?</p>
                      <div className="btn-style">
                        <Button href={'/about/fooddelight'}>Learn More</Button>
                        <div className="borderline"/>
                      </div>
                    </div>
                  </Col>
                </div>
               
                <div className="randomthought">
                  <Col sm={12}>
                    <h3>Random thoughts</h3>
                  </Col>
                  <div className="randomthought-content">
                    <Col sm={12}>
                      <div className="randomthought_bg"/>
                    </Col>
                    <Col style={{marginTop:"15%"}} sm={{offset:1, span:11}}>
                      <p >Working for Money vs. Fun vs. Meaning</p>
                      <div className="btn-style">
                        <Button href="about/wff">Learn More</Button>
                        <div className="borderline"/>
                      </div>
                    </Col> 

                  </div>
                    
                  <div className="randomthought-content">
                    <Col sm={12}>
                      <div className="randomthought_bg"/>
                    </Col>
                    <Col sm={{offset:1, span:11}}>
                      <p>Are We Living in a 3D Video Game?</p>
                      <div className="btn-style">
                        <Button href="/about/videogame">Learn More</Button>
                        <div className="borderline"/>
                      </div>
                    </Col> 
                  </div>
                </div>

                <div className="center">
                  <p>More Coming Soon...</p>
                </div>


              {/* </Container> */}
            </div>

          </FontStyle> 
        </MobileStyle>
      </Container>
    </React.Fragment>
  )
}

const AboutWithoutBubbles = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
   
  return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
}
    

export default AboutWithoutBubbles;

