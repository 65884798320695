/*For individual member page in the Lab*/
import React from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import Researchinfo from '../../content/Researchinfo.js';
import CardResearch from '../../components/CardElement/CardResearch';
import {Gradinfo, Ungradinfo} from '../../content/Memberinfo';
import {useState, useEffect} from 'react';

import BreadCrumb from '../../components/Breadcrumb';
import * as Icon from 'react-feather';
import { Container, Row, Col } from 'react-bootstrap';


const Styles = styled.div`
    img{
        width:85%;
    }
    .card{
        margin:0;
    }
    .card-img-top {
        margin:0;
    }
    .memberinfo{
    }
    h3{
        margin-top: 5rem;
    }
    a{
        color: #B30009;
        text-decoration: none;
    }
    .section{
        margin-bottom: 10rem;
      }

`;
function findGradID(targetID) {
    return Object.values(Gradinfo).filter(selectedItem =>
        selectedItem.id == targetID
    );
}

function findUngradID(targetID) {
    return Object.values(Ungradinfo).filter(selectedItem =>
        selectedItem.id == targetID
    );
}

/*breadcrumbbbbbb*/
export default function MemberPage(props) {
    const [memberInfo, setmemberInfo] = useState({id:props.match.params.id});
    useEffect(() => {
        if (props.match.params.level == "grad"){
            setmemberInfo(findGradID(memberInfo.id)[0]);
            // console.log(findGradID(memberInfo.id));
        }else{
            setmemberInfo(findUngradID(memberInfo.id)[0])
        }
    },[]);

  return(
    <Styles>
        <FontStyle>
            <React.Fragment>
                <Container>
            <br/> <br/> <br/> <br/>
                <Row>

                    <BreadCrumb 
                        link1={'/'} link2 ={'/lab'} secondlevel={'Lab'}
                        current={memberInfo.name}
                    />
                </Row>
                <Row key={memberInfo.id}>
                    <Col xs={12} md={6}>
                        <img src={memberInfo.imgsrc}  width="100%"/>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="memberinfo">
                            <h2>{memberInfo.name}</h2>
                            <br/>
                            <h4>{memberInfo.year} Student</h4>
                            <h4>{memberInfo.major}</h4>
                            <h4>UC San Diego</h4>
                        </div>
                        <h3>Contact</h3>
                        <br/>
                        <p><Icon.Mail/> &nbsp;&nbsp; <a href = {"mailto:" + memberInfo.contact_email}>{memberInfo.contact_email}</a></p>
                        <p><Icon.Globe/> &nbsp;&nbsp; <a href = {memberInfo.contact_site}>{memberInfo.contact_site}</a></p>
                       
                    </Col>
                </Row>
                    <h3>About Me</h3>
                    <p>{memberInfo.short_bio}</p> 
                    <h3>Active Research</h3>
                {/* <Row>
                    <Col xs={12} md={12}>
                        
                    </Col>
                </Row> */}
                
                <Row>
                    {Researchinfo.slice(0,2).map((ele) => {
                        return (
                            <Col xs={12} md={4}>
                            <CardResearch
                            imgsrc={ele.imgsrc} id={ele.id}
                            shortdescription={ele.shortdescription} link={ele.link} 
                            />
                            </Col>
                        );
                        }
                    )}
                </Row>
                <div className="section"/>
                </Container>
            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}


// export default withRouter(MemberPage);