/*course info */

/*import images */

import COGS100 from "../a_media/course_img/cogs100.png";
import COGS100Schedule from "../a_media/course_img/cogs100_schedule.png";

import COGS187A from "../a_media/course_img/cogs187a.png";
import COGS187B from "../a_media/course_img/cogs187b.png";
// import COGS199 from "../a_media/course_img/cogs199.png";
import COGS260 from "../a_media/course_img/cogs260.png";

import COGS100COVER from "../a_media/course_img/cogs100-cover.png";

export const UngradCourse = [
  {
    id: 1,
    coursecode: "COGS100",
    title : "COGS 100 — Cyborgs Now and in the Future",
    thumbnailimg: COGS100,
    imgsrc: COGS100COVER,
    quarter: "Fall ‘21",
    time: "Tu & Th: 2 - 3:20 PM",
    location: "CSB",
    canvas_link: "https://picsum.photos/id/24/900/590",
    description: 'Covers the theories of situated, distributed, enactive, and embodied cognition. Explains how cyborgs are a natural consequence of our current understanding of embodied minds embedded in culturally shaped niches; how mental systems can be distributed over other people and things.',
    detail_half1: 'This course covers theories of enactive, embodied, situated, distributed, and extended cognition through the lens of the cyborg.',
    detail_half2: 'We will explore how cyborgs are a natural consequence of embodied minds embedded in culturally shaped niches, as well as how mental systems can be distributed in both the social and material world.',
    detail_half3: 'We\'ll ask questions such as whether your smartphone is part of your mind, whether shoes are cyborg parts, how tools and computers make us smarter (or if they even do), and what might happen as human technology carries us beyond ourselves.',
    detail_full:'By the end of the course,',
    bullet1:'you\'ll have new theoretical tools for thinking about what cognition is, as well as how and where cognition occurs; ',
    bullet2:'you\' ll have exposure to some of the cutting-edge empirical research on these topics;',
    bullet3:'you\'ll be able to apply these skills and this knowledge both to analyze the cognitive systems which we’re a part of, and to think about the design of possible new technologies to further augment human capabilities.',
    link1: '',
    link2: '',
    course_schedule: COGS100Schedule,
  },
  {
    id: 2,
    coursecode: "COGS187A",
    title : "COGS 187A — Usability & Info. Architecture",
    thumbnailimg: COGS187A,
    imgsrc: COGS187A,
    quarter: "Fall ‘21",
    time: "Tu & Th: 2 - 3:20 PM",
    location: "CSB",
    canvas_link: "",
    description: 'Examines the cognitive basis of successful web and multimedia design. Topics: information architecture, navigation, usability, graphic layout, transaction design, and how to understand user interaction',
    detail_half1:'This course centers around projects. Lectures exist to go over material that you will need to know to perform well on the projects. Online material does not always conform closely to what happens in class, where the focus will be on examples and critique of sites as in other studio-based classes.',
    detail_half2:'Above all, this course is about developing \'good taste\' in web design. This means knowing what makes a site work and what weakens its usability.',
    detail_half3:'At the end of this course, you should have basic technical competence in designing websites to be usable and effective. We\'re sure you will also gain a greater appreciation of how hard it is to design well-structured websites that support the needs of users.',
    detail_full:'At a more theoretical level, you should understand:',
    bullet1:'the core principles of information architecture and navigation,',
    bullet2:'design rationale and effective communication in design environments,',
    bullet3:'and how design affects comprehension and behavior. ',
    link1: '',
    link2: '',
    course_schedule: "",
  },
  {
    id: 3,
    coursecode: "COGS187B",
    title : "COGS 187B — Usability & Info. Architecture",
    thumbnailimg: COGS187B,
    imgsrc: COGS187B,
    quarter: "Fall ‘21",
    time: "Tu & Th: 2 - 3:20 PM",
    location: "CSB",
    canvas_link: "",
    description: 'This course follows up on the basics of multimedia design taught in COGS 187A. Students will probe more deeply into selective topics, such as animation, navigation, graphical display of information, and narrative coherence.',
    detail_half1: 'This class is a project based studio class whose objective is to teach students the practical side of working with clients to create a complete web design that meets or exceeds the client\'s expectations. ',
    detail_half2: 'Students form teams and find a client, typically (though not necessarily) one that is outside of the university, and then work with that client to understand their goals, needs and opportunities to meet functional requirements and to design a significant website that the client likes and the professor recognizes as high quality. ',
    detail_half3: 'Because this is a studio based class, there are no real lectures. Students tackle design projects in an environment where experienced professionals review and critique their work. ',
    detail_full:'The course is structured according to the deliverables that a professional web team would need to provide. These include:',
    bullet1:'Creative Brief',
    bullet2:'Competitive Analysis and Mood Board',
    bullet3:'Functional Specification, Wireframe & Prototypes',
    detail_closing:'You will learn something of the complicated social dynamics that arise between client and designers and also what it is to meet deadlines in team.  The class teacher, TAs, IAs and others are here largely to provide you with feedback to push your designs to the next level.  The spirit of teaching is not "Let me tell you all you need to know about how to design," but rather "Let me help you to understand and improve what you\'ve done."  At the end of this course you should have a basic technical competence in the different stages of professional web site creation, short of coding the site.',
    link1: '',
    link2: '',
    course_schedule: "",
  },
  {
    id: 4,
    coursecode: "COGS199",
    title : "COGS 199 — Independent Study",
    thumbnailimg: 'https://picsum.photos/id/24/900/590',
    imgsrc: 'https://picsum.photos/id/24/900/590',
    quarter: "Fall ‘21",
    time: "Tu & Th: 2 - 3:20 PM",
    location: "CSB",
    canvas_link: "",
    description: 'Individual students work on a topic that both Prof. Kirsh and they are interested in. It’s a 10 week project with deliverables to be decided after the first meeting.',
    detail_half: 'This course centers around projects. Lectures exist to go over material that you will need to know ' +
    'to perform well on the projects. Online material does not always conform closely to what happens in class, where the focus will be on examples and critique of sites as in other studio-based classes. ',
    detail_full:'',
    link1: '',
    link2: '',
    course_schedule: "",
  },
]

export const GradCourse = [ 
  {
    id: 1,
    coursecode: "COGS260",
    title : "COGS 260 — 4E Cognition: Embedded, Embodied, Enactive and Extended",
    thumbnailimg:COGS260,
    imgsrc: COGS260,
    quarter: "Fall ‘21",
    time: "Tu & Th: 2 - 3:20 PM",
    location: "CSB",
    description: 'This course is an exploration of recent rebellions against a classical view of mind that still, in many respects, dominates cognitive science.',
    detail_half1: 'This course is an exploration of recent rebellions against a classical view of mind that still, in many respects, dominates cognitive science.',
    detail_half2:'The mind, for most of the field, is assumed to be implemented entirely in the CNS, it is essentially an information processing organ, it creates representations in a neural code that regularly correlate with a shared world of jointly perceivable attributes, objects, processes and things. Sometimes these neural encodings are well described as rule governed, sometimes rules are unhelpful and neural net models are preferred. Regardless, the cognitive system is still seen as functionally organized with major components solving core informational problems such as seeing or perceiving scenes, identifying what or who is where, understanding and generating language, social cognition, planning and reasoning, predicting future states of the environment, and legions of other functions like facial recognition that we regard as central to being human.',
    detail_half3:'But there are good reasons to think this comfortable view is simplistic.  ',
    detail_full:'Why assume the world we live in is not partly created by us through or action, selective attention and interests, our expertise and our necessarily biased expectation function?  And then there is the question of what we have become through cultural change, technological enhancement, and the rest. '
+'After reviewing the classical position subsequent lectures describe and evaluate the different E’s – Embodied, Enactive, Embedded, Extended mind and also the common inspiration derived from Situated Cognition and Distributed Cognition. Throughout, we necessarily lean on philosophy insofar as the proponents have usually been philosophers. But our goal is to evaluate the empirical contributions inspired by these metaphysical or methodological positions.  The professor will lecture for half the class, but student participation is central throughout.',
    link1: '',
    link2: '',
  },
]

export const CourseID = [
  UngradCourse, 
  GradCourse,
]

