/*speaker info */
export const Speakerinfo = [
    {
        id: 1,
        title:'Cognitive Science',
        imgsrc: 'https://picsum.photos/id/12/1200/700',
        description: 'The Department of Cognitive Science is committed to transcending theoretical boundaries rooted in traditional disciplines in pursuit of a scientific account of cognition. We promote the study of learning, perception, action, and interaction in the physical, social, and cultural world. Our inter-disciplinary vision draws from a number of disciplines, including anthropology, computer science, ethology, electrical engineering, linguistics, neurology, neurosciences, philosophy, psychology, and sociology in order to contribute to theory and apply what we learn to design.',
    },
    {
        id: 2,
        title:'Architecture',
        imgsrc: 'https://picsum.photos/id/33/1200/700',
        description: 'American architect Michael Murphy, cofounder of the nonprofit firm MASS Design Group, promotes the healing power of architecture in this 2016 presentation. He explains how his firm has used local materials and labour to create dignified architecture in Haiti, Rwanda, Democratic Republic of the Congo, and elsewhere.',
    },
    {
        id: 3,
        title:'Dance',
        imgsrc: 'https://picsum.photos/id/22/1200/700',
        description: 'Houston Ballet continues to rise to every new challenge and create new work for its dancers and audience. Join Artistic Director, Stanton Welch AM,  Associate Director of Audio Visual Services, David Rivera and Company dancers as they discuss their newest ballet, In Good Company, a group of short films set to music by The Dead South that will be released in a serial format.',
    },
    {
        id: 4,
        title:'Design',
        imgsrc: 'https://picsum.photos/id/42/1200/700',
        description: 'Previously at IBM and Airbnb, discusses how today’s companies are expected to offer seamless and coherent brand experiences. However, if the system is too strict, teams are constrained and the results are rigid and dull. Hayley explains how you can inspire unity, not uniformity, with your design language system, allowing you to remove the barriers to creativity and unlock innovation in your organisation.',
    },
]

/*consultant info */
export const Consultantinfo = [
    {
        id: 1,
        title:'Large Scale Information Architecture',
        description: 'Information architecture (IA) is far more challenging—and necessary—than ever. With the glut of information available today, anything your organization wants to share should be easy to find, navigate, and understand. But the experience you provide has to be familiar and coherent across multiple interaction channels, from the Web to smartphones, smartwatches, and beyond.',
    },
    {
        id: 2,
        title:'Interviewer for Needs & Opportunities',
        description: 'American architect Michael Murphy, cofounder of the nonprofit firm MASS Design Group, promotes the healing power of architecture in this 2016 presentation. He explains how his firm has used local materials and labour to create dignified architecture in Haiti, Rwanda, Democratic Republic of the Congo, and elsewhere.',
    },
    {
        id: 3,
        title:'Knowledge Management',
        description: 'Houston Ballet continues to rise to every new challenge and create new work for its dancers and audience. Join Artistic Director, Stanton Welch AM,  Associate Director of Audio Visual Services, David Rivera and Company dancers as they discuss their newest ballet, In Good Company, a group of short films set to music by The Dead South that will be released in a serial format.',
    },

]

  
  