/*For currently working on section used on Home page*/
import React,{useState, useEffect} from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';

import { Container, Row, Col } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Violin from '../../a_media/images/Home_violin.png';


const GlobalStyle = styled.div`
.learnmore{
  display: flex;
  justify-content: flex-end;
  color: #B30009;
}

img{
  border: 1px solid #000000;
}
`;





//lines
// .vertical-line1{
//   position: absolute;
//   width: 150px;
//   height: 0px;
//   top: 15px;
//   left: -180px;
//   border: 0.5px solid #8d8d8d;
//   transform: rotate(-90deg);
// }
// .horizontal-line1{
//   position: absolute;
//   width: 200px;
//   height: 0px;
//   left: -200px;
//   top: 46px;
//   border: 0.5px solid #8d8d8d;
// }
// .vertical-line2{
//   position: absolute;
//   width: 400px;
//   height: 0px;
//   top: 300px;
//   left: 350px;
//   border: 0.5px solid #8d8d8d;
//   transform: rotate(-90deg);
// }
// .horizontal-line2{
//   position: absolute;
//   width: 300px;
//   height: 0px;
//   left: 300px;
//   top: 395px;
//   border: 0.5px solid #8d8d8d;
// }

//lines
// const LineLeft = () =>{
//     return (
//       <React.Fragment> 
//         <div className="vertical-line1"/>
//         <div className="horizontal-line1"/>
//       </React.Fragment>
//     )
//   }
//   const LineRight = () =>{
//     return (
//       <React.Fragment> 
//         <div className="vertical-line2" />
//         <div className="horizontal-line2"/>
//       </React.Fragment>
//     )
//   }

const MobileStyles = styled.div`
//mobile style
.mobile_currentlyworking{
  position: relative;
  margin-top: 10%;
}
.mobile_currentlyworking_text{
  margin-top: 5%;
}
.mobile_currentlyworkingon_subtitle{
  background-color: #C22A2A;
  padding: 1% 2.5% 1% 1%;
  color: #fff;
  position: absolute;
  top:-23%;
  left: 7%;
  width: 95%;
  // border: 1.5px solid #000000;
  font-size:10pt;

}
.mobile_currentlyworkingon_subtitle strong{
  font-weight:600;
}
.mobile_currentlyworkingon_body{
  margin-top: 1rem;
}
`;

function MobileComponent (){
    return (
      <GlobalStyle>
        <MobileStyles>
          <FontStyle>
            <React.Fragment> 
                <Row className="mobile_currentlyworking">
                  <Container>
                    <Col xs={12} offset={2} md={6}>
                    <h3>Currently Working On</h3>
                
                    <img src={Violin} alt="currently working on" width="100%"/>     
                    </Col>
                    <Col className="mobile_currentlyworking_text" xs={12} md={5}> 
                    
                    <div className="mobile_currentlyworkingon_subtitle">
                        <strong>Why we need an embodied account of artisanal knowledge? <i>(for the Victoria and Albert Museum)</i></strong>
                    </div>
                    
                    <div className="mobile_currentlyworkingon_body">
                    <p>Like others, I believe that when masters lose their apprentices we stand to lose, as a culture, 
                        their accumulated knowledge acquired through years of working with materials, personalizing practices 
                        and passing that knowledge on. To explain a human practice without explaining how that practice 
                        co-evolved with human bodies, and with the tools and scaffolds needed to support the practice 
                        seems to me to miss the essence of what a practice is. My goal in this essay is to explain the 
                        difference in the two accounts and to introduce the type of science we need to deliver proper 
                        theories of human (manual) practices.</p>
                        <a className="learnmore" href="./currentlysubpage"> ...more</a>
                    </div>
                    </Col>
                  </Container>
                </Row>
              </React.Fragment>
            </FontStyle>
          </MobileStyles>
          </GlobalStyle>
    )
}


const DesktopStyles = styled.div`
//currently working on section for desktop
  .desktopcurrentlyworking{
    position: relative;
    margin-top: 10%;
  }

  .desktopcurrentlyworking_text{
    margin-top: 7%;
  }
  .desktopcurrentlyworkingon_subtitle{
    background-color: #C22A2A;
    padding: 2% 5% 2% 5%;
    color: #fff;
    position: absolute;
    left: -20%;
    width: 110%;
    // border: 1px solid #000000;

  }
  .desktopcurrentlyworkingon_subtitle strong{
    font-weight: 600;

  }

  .desktopcurrentlyworkingon_body{
    margin-top: 18%;
    // width: 90%;
  }
  `;


function DesktopComponent (){
    return (
      <GlobalStyle>
      <DesktopStyles>
      <FontStyle>
      <React.Fragment> 
        <Container>
            <Row className="desktopcurrentlyworking">
              
              <Col xs={12} offset={2} md={6}>
                <h3>Currently Working On</h3>
                
                {/* {visible ? <LineLeft/> : null} */}
                {/* <div className="vertical-line1"/>
                <div className="horizontal-line1"/> */}

                <img src={Violin} alt="currently working on" width="100%"/>     
              </Col>
              <Col className="desktopcurrentlyworking_text" xs={12} md={6}> 
              
                <div className="desktopcurrentlyworkingon_subtitle">
                    <strong>Why we need an embodied account of artisanal knowledge? <i>(for the Victoria and Albert Museum)</i></strong>
                </div>
                
                <div className="desktopcurrentlyworkingon_body">
                <p>Like others, I believe that when masters lose their apprentices we stand to lose, as a culture, 
                    their accumulated knowledge acquired through years of working with materials, personalizing practices 
                    and passing that knowledge on. To explain a human practice without explaining how that practice 
                    co-evolved with human bodies, and with the tools and scaffolds needed to support the practice 
                    seems to me to miss the essence of what a practice is. My goal in this essay is to explain the 
                    difference in the two accounts and to introduce the type of science we need to deliver proper 
                    theories of human (manual) practices.</p>
                    <a className="learnmore" href="./currentlysubpage"> ...more</a>

                    {/* <div className="vertical-line2" />
                    <div className="horizontal-line2"/>
                    */}
                    {/* {visible ? <LineRight/> : null} */}
                </div>
              </Col> 
            </Row>
        </Container>

        </React.Fragment>
    </FontStyle>
    </DesktopStyles>
    </GlobalStyle>
    )
}

const CurrentlyworkingHome = () => {
const [width, setWidth] = React.useState(window.innerWidth);
const breakpoint = 620;
React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
}, []);
    
return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
}


export default CurrentlyworkingHome;