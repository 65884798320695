/*For individual course page in the teaching*/
import React from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import {UngradCourse, GradCourse} from '../../content/Courseinfo';
import {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import BreadCrumb from '../../components/Breadcrumb';


const Styles = styled.div`
    .firstpara{
        margin-top: 3rem;
    }
    .card{
        margin:0;
    }
    .card-img-top {
        margin:0;
    }
    .courseInfo{
        margin-top: 13rem;
    }
    h3{
        padding-top: 5rem;
    }
    .section{
        margin-bottom: 5rem;
    }
    .coverimg{
        width:100vw;
        max-height:400px;
        object-fit: cover;
    }

`;
function GradCourseID(targetID) {
    return Object.values(GradCourse).filter(selectedItem =>
        selectedItem.id == targetID
    );
}

function UngradCourseID(targetID) {
    return Object.values(UngradCourse).filter(selectedItem =>
        selectedItem.id == targetID
    );
}

export default function CoursePage(props) {
    const [courseInfo, setcourseInfo] = useState({id:props.match.params.id});
    useEffect(() => {
        if (props.match.params.level === "grad"){
            setcourseInfo(GradCourseID(courseInfo.id)[0]);
            // console.log(GradCourse(courseInfo.id));
        }else{
            console.log(UngradCourseID(courseInfo.id),courseInfo.id);
            setcourseInfo(UngradCourseID(courseInfo.id)[0])
        }
    },[]);

  return(
    <Styles>
        <FontStyle>
            <React.Fragment>
            <br/><br/><br/>
            <img className="coverimg" src={courseInfo.imgsrc} width="100%"/>  
            <Container>
                <Row>
                    <BreadCrumb 
                        link1={'/'} link2 ={'/teaching'} secondlevel={'Teaching'}
                        current={courseInfo.coursecode}
                    />
                </Row>

                <h2>{courseInfo.title}</h2>
                <h5>{courseInfo.quarter}  &nbsp;&nbsp;| &nbsp;&nbsp; {courseInfo.time} &nbsp;&nbsp;| &nbsp;&nbsp; Location: {courseInfo.location}</h5>

                <Row className="firstpara">
                    <Col xs={12} md={12}>
                        <p>{courseInfo.detail_half1}</p>
                        <p>{courseInfo.detail_half2}</p>
                        <p>{courseInfo.detail_half3}</p>
                    </Col>
                    {/* <Col xs={12} md={6}>
                        <img src={courseInfo.imgsrc} width="100%"/>   
                    </Col> */}
                </Row>
                <p> <br/>
                    {courseInfo.detail_full}
                <ul>
                    <li>{courseInfo.bullet1}</li>
                    <li>{courseInfo.bullet2}</li>
                    <li>{courseInfo.bullet3}</li>
                </ul>    
                {courseInfo.detail_closing}            
                </p>

                <h3>Videos</h3>
                <Row>
                    <Col style={{minHeight:'300px', marginBottom:'2em'}} xs={12} md={6}> 
                    {/* <br/><br/><br/><br/><br/> */}
                        <iframe 
                            src={courseInfo.link1}
                            frameborder='0'
                            allow='autoplay; encrypted-media'
                            title='video'
                            width="100%"
                            height="100%"
                        />   
                    </Col>   
                    <Col style={{minHeight:'300px', marginBottom:'2em'}} xs={12} md={6}>
                        <iframe 
                            src={courseInfo.link2}
                            frameborder='0'
                            allow='autoplay; encrypted-media'
                            title='video'
                            width="100%"
                            height="100%"
                        />   
                    </Col>
                </Row>
           
                <h3>Course Schedule</h3>
                <Row>
                    <Col xs={12} md={12}>
                        <img src={courseInfo.course_schedule} width="100% "/>
                    </Col>
                </Row>

                <div className="section"/>

            </Container>
            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}


// export default withRouter(MemberPage);