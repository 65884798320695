import styled from 'styled-components';  

const FontStyle = styled.div`
html {
    font-size: 100%;
}
//title of everypage
    h1{
        font-family: bebas-neue-pro-semiexpanded, sans-serif;
        font-size: 20px;
        font-weight: 900;
        //margin-top:2rem;
    }

    h2{
        margin-top:5%;
        font-family: bebas-neue-pro-semiexpanded, sans-serif;
        font-size: 36pt;
        font-weight: 600;
        color: #434343;
        // letter-spacing: -0.1rem;
    }

    @media only screen and (max-width: 800px) {
        h2{
            font-size: 24pt;
        }
    }


//heading of sections 
    h3{
        font-family: bebas-neue-pro-semiexpanded, sans-serif;
        font-size: 22pt;
        font-weight: 600;
        // margin-top: 3rem;
    }
    
    h4{
        font-family: 'Lato', sans-serif;
        font-size: 22px;
        font-weight: 600;
    }

    h5{
        font-family: 'Lato', sans-serif;
        font-size: 14pt;
        opacity: 50%;        
    }

    p{
        font-family: 'Lato', sans-serif;
        font-size: 12pt;
        font-weight: 400;
        line-height: 24px;

    }
    caption{
        font-family: 'Lato', sans-serif;
        font-size: 14pt;
    }

    a{
        font-family: 'Lato', sans-serif;
    }
    ul{
        font-family: 'Lato', sans-serif;
        font-size: 12pt;
        font-weight: 400;
        line-height: 24px;
    }

`;

export default FontStyle;
