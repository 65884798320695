/*For currently working on section used on Home page*/
import React,{useState, useEffect} from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';

import { Container, Row, Col } from 'react-bootstrap';

import Violincover from '../../a_media/images/Home_Subpage_currentlyworkingon.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';


const Style = styled.div`
.learnmore{
  display: flex;
  justify-content: flex-end;
  color: #B30009;
}

img{
  border: 1px solid #000000;
}

.breadcrumb{
    margin-top: 0.5rem;
    background-color: rgba(255, 255, 255, 0) !important;
    margin-bottom: 0 !important;
}

.breadcrumb-item a{
    color: #FF3A3A;
    // color: #B30009;
}
a:hover{
    text-decoration:none;
}

.currentlyworkingon-text{
    margin-top: 10em;
    margin-bottom: 14em;
    margin-left:4em;
}

.desktopcurrentlyworkingon_subtitle{
    position: absolute;
    background-color: #C22A2A;
    padding: 1% 5% 1% 5%;
    color: #fff;
    margin-top: 2.5em;
    left: 0;
    padding-left:22%;
    width: 85%;
    // border: 1px solid #000000;
}

@media only screen and (max-width: 620px) {
    .currentlyworkingon-text{
        margin-top: 2em;
        margin-bottom: 10em;
        margin-left:0em;
    }
}
`;

function SubpageCurrently (){
    return (
        <Style>
            <FontStyle>
                <React.Fragment> 
                    <br/><br/><br/>
                    <img src={Violincover} alt="currently working on" width="100%"/>     
                    <Container>
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>Currently Working On</Breadcrumb.Item>
                            </Breadcrumb> 
                        </Row>
                        <h2>Currently Working on</h2>
                        <div className="desktopcurrentlyworkingon_subtitle">
                            <strong>Why we need an embodied account of artisanal knowledge? <i>(for the Victoria and Albert Museum)</i></strong>
                        </div>
                        <div className="currentlyworkingon-text">
                        <p >Like others, I believe that when masters lose their apprentices we stand to lose, 
                            as a culture, their accumulated knowledge acquired through years of working with 
                            materials, personalizing practices and passing that knowledge on. To explain a human 
                            practice without explaining how that practice co-evolved with human bodies, and with 
                            the tools and scaffolds needed to support the practice seems to me to miss the essence 
                            of what a practice is. My goal in this essay is to explain the difference in the two 
                            accounts and to introduce the type of science we need to deliver proper theories of 
                            human (manual) practices.</p>
                            <p >Like others, I believe that when masters lose their apprentices we stand to lose, 
                            as a culture, their accumulated knowledge acquired through years of working with 
                            materials, personalizing practices and passing that knowledge on. To explain a human 
                            practice without explaining how that practice co-evolved with human bodies, and with 
                            the tools and scaffolds needed to support the practice seems to me to miss the essence 
                            of what a practice is. My goal in this essay is to explain the difference in the two 
                            accounts and to introduce the type of science we need to deliver proper theories of 
                            human (manual) practices.</p>
                            <p >Like others, I believe that when masters lose their apprentices we stand to lose, 
                            as a culture, their accumulated knowledge acquired through years of working with 
                            materials, personalizing practices and passing that knowledge on. To explain a human 
                            practice without explaining how that practice co-evolved with human bodies, and with 
                            the tools and scaffolds needed to support the practice seems to me to miss the essence 
                            of what a practice is. My goal in this essay is to explain the difference in the two 
                            accounts and to introduce the type of science we need to deliver proper theories of 
                            human (manual) practices.</p>
                        </div>
                    </Container>

    
                </React.Fragment>
            </FontStyle>
        </Style> 
    )
}

export default SubpageCurrently;
