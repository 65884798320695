import React from 'react';
import styled from 'styled-components';

import {Gradinfo, Ungradinfo} from './content/Memberinfo';
import CardMember from './components/CardElement/CardMember';
import MemberPage from './components/DisplayPage/MemberPage';
import { Container, Row, Col } from 'react-bootstrap';

import FontStyle from './FontStyles';
import SimpleMap from './components/Googlemap';

import * as Icon from 'react-feather';
import ActiveResearchLab from './components/CardElement/Lab_ActiveResearch';

import LabImg from './a_media/lab_img/labimg.png';


import {BrowserRouter as Router, Link} from 'react-router-dom'


const Styles = styled.div`
  a:hover{
    text-decoration: none;
  }
  .labinfo{
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    padding: 1rem;
  }
  .labintro{
      margin-top:3rem;
  }
  .divider{
    border: 0.5px solid #DCDCDC;
    width: 80%;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  h3{
      margin-top: 5rem;
  }
  .section{
    margin-bottom: 10rem;
  }
`;

export const Lab = () => {
    return(
    <Styles>
        <FontStyle>
            <React.Fragment>
                <Container>
            <br/> <br/> <br/> <br/>
                <h2>Interactive Cognition Lab</h2>
                <h5> "How Interaction Drives Thinking"</h5>   
                <Row> 
                    <Col xs={12} md={6}>
                        <img src={LabImg} alt="labimg" width="100%"/>
                    </Col>
                    <Col xs={12} md={6}>
                    <div className="labinfo">
                        <span style={{display:'flex'}}>
                            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.9167 31.7333V0H4.08333V31.7333H0V34H35V31.7333H30.9167ZM28.5833 31.7333H18.6667V27.2H16.3333V31.7333H6.41667V2.26667H28.5833V31.7333Z" fill="#000103" />
                            <path d="M10.499 20.4004H12.8324V22.6671H10.499V20.4004Z" fill="#000103" />
                            <path d="M16.333 20.4004H18.6663V22.6671H16.333V20.4004Z" fill="#000103" />
                            <path d="M22.167 20.4004H24.5003V22.6671H22.167V20.4004Z" fill="#000103" />
                            <path d="M10.499 13.5996H12.8324V15.8663H10.499V13.5996Z" fill="#000103" />
                            <path d="M16.333 13.5996H18.6663V15.8663H16.333V13.5996Z" fill="#000103" />
                            <path d="M22.167 13.5996H24.5003V15.8663H22.167V13.5996Z" fill="#000103" />
                        </svg>
                            <h4>Interactive Cognition Lab</h4>
                        </span>

                        <h5>Cognitive Science 0515</h5>
                        <p>University of California, San Diego<br/>
                        Social Science Research Building 100 (SSRB 100)<br/>
                        <a target="_blank" href="https://www.google.com/maps/place/Social+Science+Research+Building/@32.8807041,-117.2422653,17z/data=!3m1!4b1!4m5!3m4!1s0x80dc06c111311fd1:0xde91c40ce5892283!8m2!3d32.8807041!4d-117.2400766">
                        Get directions</a>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon.Phone />&nbsp;&nbsp;(858) 534-6773</p> 
                        {/* The map */}
                        <div style={{height:'200px'}}>
                            <SimpleMap/>
                        </div>
                    </div>
                    </Col>
                </Row>

                <Row className="labintro">
                    <Col xs={12} md={12}>
                    <h4>The Interactive Cognition Lab primarily focuses on research in basic cognitive science:</h4>
                    <p>What is thinking? How are people creative? What makes something visually complex?
                        How do we use external representations? 
                        We conduct experiments 
                        motivated by careful ethnographic studies of real-life situations; 
                        we make computational models of people's activity and we build interactive 
                        installations that are artistically interesting and serve a cognitive function, 
                        such as priming the mirror neuron system to enhance the appreciation of dance.</p>
                    </Col>
                </Row>
                
                    <div className="activeresearch">
                        <ActiveResearchLab/>
                    </div>
<br/>
                <h3>Openings for Research</h3>
                    <p>Current openings for the Interactivity Lab:
                    <ul>
                        <li>Augmented Video</li>
                        <li>E-Learning</li>
                        <li>Augmented Video</li>
                        <li>Design of collaborative environments</li>
                    </ul>
                    Interested in research? Send any questions to <a href="mailto:dkirsh@ucsd.edu">dkirsh@ucsd.edu</a></p>
               
                <h3>Lab Members</h3>
                <h4>Grad students</h4>
                <Row>
                    {Gradinfo.map((ele) => {
                        return (
                        <Col xs={12} md={3}>
                            <Link to={"/lab/grad/" + ele.id + "/" + ele.name}>
                                <CardMember
                                imgsrc={ele.imgsrc}
                                name={ele.name} major={ele.major} year={ele.year} 
                                description={ele.description}
                                />
                            </Link>
                        </Col>
                        );
                    })}
                   
                </Row>
                <br/><br/>
                <h4>Undergrad students</h4>
                <Row className="section"> 
                    {Ungradinfo.map((ele) => {
                        return(
                        <Col xs={12} md={3}>
                            <Link to={"/lab/undergrad/" + ele.id + "/" + ele.name}>
                                <CardMember
                                id={ele.id}
                                imgsrc={ele.imgsrc}
                                name={ele.name} major={ele.major} year={ele.year} 
                                description={ele.description}
                                />
                            </Link>
                        </Col>
                        );
                    })}
                </Row>
            </Container>
        </React.Fragment>
    </FontStyle>
    </Styles>
    );
}