/*For course card used on Home page*/
import React, { useState }from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResearchMap from '../../content/Research/research_map.json';

import PubDropdown from '../../components/PubDropdown';
import { ThemeContext } from '../../App';

const Styles = styled.div`
    .dropdown-toggle{
        border: 0.5px solid #DCDCDC;
        background-color: #fff !important;
        color: #000 !important;
        margin-bottom: 5%;
    }
    h3{
        margin-top:0rem !important;
    }
    .researchcard{
        display: flex;
        flex-direction: column;
    }
    
    .button-section .btn-primary{
        border: none;
        background-color: #B30009 !important;
        color: #fff !important;
    }
    .button-section a{
        color: #fff !important;
    }
    .button-section{
        display:flex;
        justify-content:flex-end;
    }
    .card-text{
        min-height: 15rem;
    }

    a:active{
        background-color: #B30009;
    }
`;

function ActiveResearchLab(){
    const [currentTopic, setcurrentTopic] = useState("Architecture"); 

  return(
    <React.Fragment>
    <Styles>
      <FontStyle>
        <ThemeContext.Provider value={{ currentTopic, setcurrentTopic }}>
            <Row className="mt-5 mb-3">
                <Col xs={12} md={6}>
                    <h3>Active Research Areas</h3>
                </Col>
                <Col xs={12} md={6}>
                    <PubDropdown
                        title={'Choose a research topic'}
                        dropdownItemList={Object.keys(ResearchMap)}
                    />
                </Col>
            </Row>

            {Object.keys(ResearchMap).filter((key) => key === currentTopic).map((research,index) => {
            return (
                <div research={index}>
                    <Row>
                        <Col xs={12} md={6}>
                            <img src={ResearchMap[research].thumbnailimg} alt={research} width="100%" />
                        </Col>  
                        <Col xs={12} md={6}>
                            <div className="researchcard" >
                                <div className="card-text">
                                    <h4>{research}</h4>
                                    <p>{ResearchMap[research].short_description}</p>
                                </div>
                                <div className="button-section">
                                    <Button href={"/research/" + research}>
                                        ... More
                                    </Button>
                                </div>
                            </div>
                        </Col> 
                    </Row>
                </div>
            )
        })}
           
        </ThemeContext.Provider>
        </FontStyle>
    </Styles>
    </React.Fragment>
  );
}

export default ActiveResearchLab;
