/*For card in the Research*/
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';


const Styles = styled.div`
  .card{
    border:none!important;
  }
  .card-img-top{
    border-radius: 2px;
    max-height:280px;
  }
  .card-body{
    padding: 20px 0px 0px;
    min-height: 230px;
  }
  .btn-primary{
    border: none;
    background-color: #B30009 !important;
    color: #fff !important;
  }
  .button-section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position:relative;
  }

  @media only screen and (max-width: 800px) {
    .card-body{
      padding: 20px 0px 0px;
      min-height: 250px;
    }
  }
`;

function CardResearch(props){
  return(
    /* without btn & with sub title*/
    <Styles>
      <FontStyle>
        <Card border="light">
          <Card.Img variant="top" src={props.thumbnailimg} />
          <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text>
              {props.shortdescription}
            </Card.Text>
            
          </Card.Body>
          <div class="button-section">
              <Button href={props.link}>... More</Button> &nbsp;&nbsp;
            </div>
        </Card>
        
      </FontStyle>
    </Styles>
  );
}

export default CardResearch;
