/* For card in the Home & Teaching */
import React from 'react';
// import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
// import CardDeck from 'react-bootstrap/CardDeck'
import {BrowserRouter as Router, Link} from 'react-router-dom'


/*course card style */
const Styles = styled.div`
  img{
    border-radius: 0px;
    margin-bottom:2%;
  }

  .canvaslink a{
    color:#B30009;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: flex-end;
  }

  .btn-primary{
    border: none;
    background-color: #B30009 !important;
    color: #fff !important;
  }

  .card-course{
    margin-top: 20%;
    margin-bottom: 3rem;
    width:90%;
  }

  .coursecard{
    display: flex;
    flex-direction: row;
  }

  .button-section{
    position:absolute;
    bottom:1rem;
    right: 8%;
  }

  @media only screen and (max-width: 800px) {
    .card-course{
      width:100%;
    }
    .button-section{
      right: 1%;
    }
  }
`;

/*course card format */
function CardCourse(props){

  return(
    /* with btn & with sub title*/
    <Styles>
      <FontStyle>
        <div className = "card-course">
          <div className="canvaslink">
            <a target="_blank" href={props.canvas_link}>Canvas Link</a>
          </div>
          <h4>{props.title}</h4>
          <p>{props.quarter}  &nbsp;&nbsp;| &nbsp;&nbsp; {props.time} &nbsp;&nbsp;| &nbsp;&nbsp; Location: {props.location}</p>
          <img src={props.thumbnailimg} width="100%"/>
          <div className="coursecard">
            <p>{props.description}</p>
            <div class="button-section">
              <Button href={props.link}>... More</Button> &nbsp;&nbsp;
            </div>
          </div>
        </div>
      </FontStyle>
    </Styles>
  );
}

export default CardCourse;

