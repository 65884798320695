import React from 'react';
import styled from 'styled-components';

import { Container, Row, Col } from 'react-bootstrap';
import {BrowserRouter as Router, Link,} from 'react-router-dom'

import {Aboutmeinfo} from '../../content/Aboutmeinfo';
import {ImgLeft, ImgRight} from '../CardElement/AboutMe_btn';
// import ImgRight from './components/CardElement/ImgRight';
import Button from 'react-bootstrap/Button';

import FontStyle from '../../FontStyles';

//import image
import FirstBg from '../../a_media/about_img/regulardesign/first_bg.png';
import SecondBg from '../../a_media/about_img/regulardesign/second_bg.png';
import ThirdBg from '../../a_media/about_img/regulardesign/third_bg.png';
import FourthBg from '../../a_media/about_img/regulardesign/fourth_bg.png';


const Styles = styled.div`
  h3{
    margin-top: 3rem;
    color: #000;
  }
  p{
    color: #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // font-weight: 400;
    line-height: 22px;
  }

  .section{
    margin-bottom: 10rem;
  }
//bottom section: randomthought etc

  .center{
    position: relative;
    text-align: center;
    margin-top: 8%;
  }

  .randomthought-content{
    margin-top:15rem;
  }
  .content-container{
    margin-top: 3%;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #4D4D4D;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #4D4D4D;
  }
  
  .separator:not(:empty)::before {
    margin-right: 1em;
  }
  
  .separator:not(:empty)::after {
    margin-left: 1em;
  }

//button css
.btn-style{
  position: relative;
}
.btn-primary{
  position: absolute;
  border-radius: 0px;
  background-color: #000;
  border: none;
  width: 160px;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  z-index:1;
}

.borderline{
  border: 2px solid #000;
  width: 160px;
  height: 40px;
  z-index:0;
  position: absolute;
  margin-top: 2%;
  margin-left: 2%;
}

.text-section{
  padding-top: 5%;
}

//1 st section: about me
.firstbg{
  position:absolute;
  top:50px;
  left:-40px;
  z-index:-1;
  width:75%;
}
// .firstsection{
//   position:relative;
//   margin-top: 10%;
// }

//2nd section: artist entrance
.secondbg{
  position:absolute;
  top:690px;
  left:350px;
  z-index:-1;
  width:70%;
}

// .secondsection{
//   position:relative;
//   margin-top: 23%;
// }

//3rd section: travel
.thirdbg{
  position:absolute;
  top:1130px;
  left:-50px;
  z-index:-1;
  width:66%;
}
// .thirdsection{
//   position:relative;
//   margin-top: 25%;
// }

//4th section: food
.fourthbg{
  position:absolute;
  top:1650px;
  left:250px;
  z-index:-1;
  width:70%;
}
// .fourthsection{
//   position:relative;
//   margin-top: 28%;
// }

@media only screen and (max-width: 800px) {
  .center{
    position: relative;
    text-align: center;
    margin-top: 20%;
  }

  //1 st section: about me
  .firstbg{
    position:absolute;
    top:120px;
    left:-120px;
    z-index:-1;
    width:150%;
    height:55%;
  }

  //2nd section: artist entrance
  .secondbg{
    position:absolute;
    top:950px;
    left:-100px;
    z-index:-1;
    width:150%;
    height:55%;
    transform: rotate(160deg);
  }

  //3rd section: travel
  .thirdbg{
    position:absolute;
    top:1950px;
    left:-80px;
    z-index:-1;
    width:180%;
    height:55%;
  }

  //4th section: food
  .fourthbg{
    position:absolute;
    top:2450px;
    left:-50px;
    z-index:-1;
    width:150%;
  }
}

`;

export const AboutRegular = () => {
    return(
      <Styles>
      <FontStyle>
        <React.Fragment>
          <br/><br/><br/><br/>

        <img className="firstbg" src={FirstBg}/> 
        {/* <Container className="firstsection">
            <Row>
              <Col xs={12} md={{offset:1, span:5}}>
                <div className="text-section">
                  <h3>About Me</h3>
                  <p>I’ve led a life of research, collaboration, travel and sharing 
                    ideas. It’s started early, but took a twist when, as an undergrad, 
                    I read Ouspenski and Gurdjieff. Even better than rock festivals! After finishing 
                    my specialization in economics early at Univ of Toronto I devoted 
                    my final year to philosophy... </p>
                </div>
                <div className="btn-style">
                  <Button>More About Me</Button>
                  <div className="borderline"/>
                </div>
              </Col>
              <Col xs={{offset:1,span:8}} md={{offset:1, span:4}}>
                <img src={FirstImg} width="100%"/>
              </Col>
            </Row>
        </Container> */}
        
        <img className="secondbg" src={SecondBg}/>
        {/* <Container className="secondsection">
          <Row>
            <Col xs={{offset:1,span:8}} md={{offset:1, span:4}}>
              <img src={SecondImg} width="100%"/>
            </Col>
            <Col xs={12} md={{offset:1, span:5}}>
              <div className="text-section">
                <h3>Through the Artist's Entrance</h3>
                <p>I once thought the best you could do is to make your life a work of art. 
                It’s a possible solution to the problem of meaning.</p>
              </div>
              <div className="btn-style">
                <Button>Learn More</Button>
                <div className="borderline"/>
              </div>
            </Col>
          </Row>
        </Container> */}
         
          <img className="thirdbg" src={ThirdBg}/>
          {/* <Container className="thirdsection">
              <Row>
                <Col xs={12} md={{offset:1, span:5}}>
                  <h3>Traveling the World</h3>
                  <p>Even monks find it lethally boring to meditate day after day for years. 
                    Twelve years is the crisis point you’re often told. So how to break the 
                    tedium of profound sameness?</p>
                    <div className="btn-style">
                      <Button>Learn More</Button>
                      <div className="borderline"/>
                    </div>
                </Col>
                <Col xs={{offset:1,span:8}} md={{offset:1, span:4}}>
                  <img src={ThirdImg} width="100%"/>
                </Col>
              </Row>
          </Container> */}

          <img className="fourthbg" src={FourthBg}/>
          
          {/* <Container className="fourthsection">
            <Row>
              <Col xs={{offset:1,span:8}} md={{offset:1, span:4}}>
                  <img src={FourthImg} width="100%"/>
              </Col>
              <Col xs={12} md={{offset:1, span:5}}>
                <h3>The Delight of Food</h3>
                <p>Why should the delight one takes be more tied to primitive sensing than 
                to interpretation based on knowledge, history and context?</p>
                <div className="btn-style">
                  <Button>Learn More</Button>
                  <div className="borderline"/>
                </div>
              </Col>
            </Row>
          </Container> */}
        <Container>
           {Aboutmeinfo.map((ele) => {
               if(ele.id % 2 == 0){
                    return (
                        // <Link to={"/about/" + ele.id + "/" + ele.title}>
                            <ImgLeft
                                imgsrc_regular={ele.imgsrc_regular}
                                title={ele.title}
                                description={ele.description}
                                link={"/about/" + ele.id + "/" + ele.title}
                            />
                        // </Link>
                    ); 
                }else{
                    return (
                        // <Link to={"/about/" + ele.id + "/" + ele.title}>
                            <ImgRight
                                imgsrc_regular={ele.imgsrc_regular}
                                title={ele.title}
                                description={ele.description}
                                link={"/about/" + ele.id + "/" + ele.title}
                            />
                        // </Link>
                    );
                }      
            })}
        </Container>
            <Container className="randomthought-content">
              <h3 className="separator">Random Thoughts</h3>

              <Row className="content-container">
                <Col style={{marginTop:"2rem"}}xs={12} md={{span:6}}>
                  <h3>Working for Money vs. Fun vs. Meaning</h3>
                  <div className="btn-style">
                    <Button href='/about/wff'>Learn More</Button>
                    <div className="borderline"/>
                  </div>
                </Col> 
                <Col style={{marginTop:"2rem"}} xs={12} md={{offset:1, span:5}}>
                  <h3>Are We Living in a 3D Video Game?</h3>
                  <div className="btn-style">
                    <Button href='/about/videogame'>Learn More</Button>
                    <div className="borderline"/>
                  </div>
                </Col> 
              </Row>
            </Container>

                <div className="center">
                  <p>More Coming Soon...</p>
                </div>


            <div className="section"/>
         
        </React.Fragment>
      </FontStyle>
      </Styles>
    );
}