/*about me info*/

import FirstImg from '../a_media/about_img/regulardesign/first_img.png';
import SecondImg from '../a_media/about_img/regulardesign/second_img.png';
import ThirdImg from '../a_media/about_img/regulardesign/third_img.png';
import FourthImg from '../a_media/about_img/regulardesign/fourth_img.png';

//images for the previous design
import RegularPersonally1 from "../a_media/about_img/regulardesign/z_subpage_personally_1.png";
import RegularPersonally2 from "../a_media/about_img/regulardesign/z_subpage_personally_2.png";
import RegularPersonally3 from "../a_media/about_img/regulardesign/z_subpage_personally_3.png";
import RegularPersonally4 from "../a_media/about_img/regulardesign/z_subpage_personally_4.png";
import RegularPersonally5 from "../a_media/about_img/regulardesign/z_subpage_personally_5.png";
import RegularPersonally6 from "../a_media/about_img/regulardesign/z_subpage_personally_6.png";
import RegularPersonally7 from "../a_media/about_img/regulardesign/z_subpage_personally_7.png";

import RegularTravel0 from "../a_media/about_img/regulardesign/z_subpage_travel_0.png";
import RegularTravel11 from "../a_media/about_img/regulardesign/z_subpage_travel_1_1.png";
import RegularTravel12 from "../a_media/about_img/regulardesign/z_subpage_travel_1_2.png";
import RegularTravel13 from "../a_media/about_img/regulardesign/z_subpage_travel_1_3.png";
import RegularTravel21 from "../a_media/about_img/regulardesign/z_subpage_travel_2_1.png";
import RegularTravel22 from "../a_media/about_img/regulardesign/z_subpage_travel_2_2.png";
import RegularTravel23 from "../a_media/about_img/regulardesign/z_subpage_travel_2_3.png";
import RegularTravel31 from "../a_media/about_img/regulardesign/z_subpage_travel_3_1.png";
import RegularTravel32 from "../a_media/about_img/regulardesign/z_subpage_travel_3_2.png";
import RegularTravel33 from "../a_media/about_img/regulardesign/z_subpage_travel_3_3.png";
import RegularTravel41 from "../a_media/about_img/regulardesign/z_subpage_travel_4_1.png";
import RegularTravel42 from "../a_media/about_img/regulardesign/z_subpage_travel_4_2.png";
import RegularTravel43 from "../a_media/about_img/regulardesign/z_subpage_travel_4_3.png";

import RegularArtist1 from "../a_media/about_img/regulardesign/z_subpage_artist_1.png";
import RegularArtist2 from "../a_media/about_img/regulardesign/z_subpage_artist_2.png";
import RegularArtist3 from "../a_media/about_img/regulardesign/z_subpage_artist_3.png";

import RegularFood1 from "../a_media/about_img/regulardesign/z_subpage_food_1.png";
import RegularFood2 from "../a_media/about_img/regulardesign/z_subpage_food_2.png";
import RegularFood3 from "../a_media/about_img/regulardesign/z_subpage_food_3.png";
import RegularFood4 from "../a_media/about_img/regulardesign/z_subpage_food_4.png";


//images for Carole's design
import BubblesPersonally1 from "../a_media/about_img/bubblesdesign/z_subpage_personally_1.png";
import BubblesPersonally2 from "../a_media/about_img/bubblesdesign/z_subpage_personally_2.png";
import BubblesPersonally3 from "../a_media/about_img/bubblesdesign/z_subpage_personally_3.png";
import BubblesPersonally4 from "../a_media/about_img/bubblesdesign/z_subpage_personally_4.png";
import BubblesPersonally5 from "../a_media/about_img/bubblesdesign/z_subpage_personally_5.png";
import BubblesPersonally6 from "../a_media/about_img/bubblesdesign/z_subpage_personally_6.png";
import BubblesPersonally7 from "../a_media/about_img/bubblesdesign/z_subpage_personally_7.png";

import BubblesArtist1 from "../a_media/about_img/bubblesdesign/z_subpage_artist_1.png";
import BubblesArtist2 from "../a_media/about_img/bubblesdesign/z_subpage_artist_2.png";
import BubblesArtist3 from "../a_media/about_img/bubblesdesign/z_subpage_artist_3.png";

import BubblesTravel1 from "../a_media/about_img/bubblesdesign/z_subpage_travel_1.png";

import BubblesFood1 from "../a_media/about_img/bubblesdesign/z_subpage_food_1.png";
import BubblesFood2 from "../a_media/about_img/bubblesdesign/z_subpage_food_2.png";
import BubblesFood3 from "../a_media/about_img/bubblesdesign/z_subpage_food_3.png";
import BubblesFood4 from "../a_media/about_img/bubblesdesign/z_subpage_food_4.png";

/* about me landing page info
    (images for Carole's design are imported on the pages themselves
    rather than here for different screen size changes)
*/
export const Aboutmeinfo = [
    {
        id: 1,
        title: 'About Me Personally',
        description: 'I’ve led a life of research, collaboration, travel and sharing ideas. It started early, but took a twist when, as an undergrad, I read Ouspenski and Gurdjieff. Even better than rock festivals! After finishing my specialization in economics early at Univ of Toronto I devoted my final year to philosophy... ',
        bold_description: '',
        imgsrc_regular:FirstImg,
    },
    {
        id: 2,
        title: 'Through the Artist’s Entrance',
        description: 'I once thought the best you could do is to make your life a work of art. It’s a possible solution to the problem of meaning.',
        bold_description: '',
        imgsrc_regular:SecondImg,
    },
    {
        id: 3,
        title: 'Traveling the World',
        description: 'Even monks find it lethally boring to meditate day after day for years. Twelve years is the crisis point you’re often told. So how to break the tedium of profound sameness?',
        bold_description: '',
        imgsrc_regular:ThirdImg,
    },
    {
        id: 4,
        title: 'The Delight of Food',
        description: 'Why should the delight one takes be more tied to primitive sensing than to interpretation based on knowledge, history and context?',
        bold_description: '',
        imgsrc_regular:FourthImg,
    },
]


/* subpage: about me personally*/
export const AboutmePersonally = [
    {
        id: 1,
        title: 'About Me Personally',
        description: ' I’ve led a life of research, collaboration, travel and sharing ideas. It started early, but took a twist when, as an undergrad, I read Ouspenski and Gurdjieff. Even better than rock festivals! After finishing my specialization in economics early at Univ of Toronto I devoted my final year to philosophy. ',
        bold_description: '',
        imgsrc_regular: RegularPersonally1,
        imgsrc_bubbles:BubblesPersonally1,
        imgsrc_caption:'',
    },
    {
        id: 2,
        title: 'About Me Personally',
        description: 'With my BA behind me, I wondered for a few years in central Asia and India in search of the miraculous and remarkable people, following the inspiration of great books. I practiced with Yogis, Sufis, and Buddhists, and travelled to Jerusalem to learn about ancient music before the fall of the first temple.',
        bold_description: '',
        imgsrc_regular:RegularPersonally2,
        imgsrc_bubbles:BubblesPersonally2,
        imgsrc_caption:'Barefoot for a year in India',

    },
    {
        id: 3,
        title: 'About Me Personally',
        description: 'After India I returned to U of T in my hometown to study western philosophy. I wasn’t sure I could return to school given the freedom of travel. But all-night meditation is good preparation for anything that requires long concentration. After two years, I went off to Oxford to study with the famous analytic philosopher Sir A.J Ayer, and later Gareth Evans. ',
        bold_description: '',
        imgsrc_regular:RegularPersonally3,
        imgsrc_bubbles:BubblesPersonally3,
        imgsrc_caption:'Freddie Ayer about to beat me at Chess - Oxford',
    },
    {
        id: 4,
        title: 'About Me Personally',
        description: 'I discovered computational theory and AI at Oxford, and somehow made it to MIT after my D.Phil to do a post doc in the AI Lab with Patrick Winston, the director. I even had the office beside Marvin Minsky and a few years later served as Associate Director for a while. You can guess how much happier my parents were at my new job prospects once part of a computer science dept.',
        bold_description: '',
        imgsrc_regular:RegularPersonally4,
        imgsrc_bubbles:BubblesPersonally4,
        imgsrc_caption:'Patrick Winston at the AI Lab MIT',
    },
    {
        id: 5,
        title: 'About Me Personally',
        description: 'After 5 years of super fun, I again got lucky and landed one of the first three openings at the first Cognitive Science dept in the world at UCSD.  You might think I am being falsely modest but there were 600 candidates for those 3 slots and you can be sure at least 200 had better cv’s.',
        bold_description: '',
        imgsrc_regular:RegularPersonally5,
        imgsrc_bubbles:BubblesPersonally5,
        imgsrc_caption:'Cognitive Science Building at UCSD',
    },
    {
        id: 6,
        title: 'About Me Personally',
        description: 'In 1998 I went to DC so my wife could continue her job at the World Bank and somehow I was in the right place and the right time to become a consultant designing the information architecture for WB’s external website. That worked out and I was hired to completely redesign their intranet of 300K pages. We returned to CA in 2002 and raised our little girls here.  ',
        bold_description: '',
        imgsrc_regular:RegularPersonally6,
        imgsrc_bubbles:BubblesPersonally6,
        imgsrc_caption:'World Bank Headquarters in Washington, D.C',
    },
    {
        id: 7,
        title: 'About Me Personally',
        description: 'In the last dozen years, I shifted my focus to choreography, architecture, and design. If anyone ever tells you that luck does not play a major role in almost everyone’s life - whether good or bad - don’t listen!',
        bold_description: 'Chance may favor the prepared mind - so prepare. But you still need good fortune.',
        imgsrc_regular:RegularPersonally7,
        imgsrc_bubbles:BubblesPersonally7,
        imgsrc_caption:'London Choreographer Wayne McGregor observing his dancers',
    },
]

/* subpage: through the Artist’s Entrance*/
export const ArtistEntrance = [
    {
        id: 1,
        title: 'Through the Artist’s Entrance',
        description: 'I’m no artist by any stretch.  Imagine my amusement and thrill when, all of a sudden, I was treated as one!  The central idea of ethnography is that the world you see, and the world you encounter depends on how you are treated, the concepts you have, and the history of your interactions. If you are shopper you don’t know what the store looks and feels like to the staff or to store owners.  If you’re a shoplifter you look at the store completely differently too. You have a different set of skills.  Ethnography is about sitting down and slowly letting the other worlds in. It’s like travel when you have way too much time. But you have to engage it as the other to really develop their concepts and ways of seeing.  I got a bye just because I walked in on the coat tails of others.',
        bold_description: '',
        imgsrc_regular:RegularArtist1,
        imgsrc_bubbles:BubblesArtist1,
        imgsrc_caption:'',
    },
    {
        id: 2,
        title: 'Through the Artist’s Entrance',
        description: 'My first real taste of the artist’s side of life happened when Wayne McGregor and the Random Dance Company came to UCSD to perform and to start the \'making\' process of a new dance. I’d been here at UCSD for 20 years but in a matter of one week my relation to the campus and feeling toward the university changed.  Stages and venues, that’s what it was all about now.  Once we started working, we’d enter at all times.  I was capturing the entire process and deeply embedded in the Wayne\'s creative process. So it was natural to enter through the stage door and go backstage. There’s a whole world there.  In fact though one the venues, the Mandeville Auditorium, was not 100 yards from the Cogsci building my relation to the entire campus changed.  I felt part of the University in ways I never had.',
        bold_description: '',
        imgsrc_regular:RegularArtist2,
        imgsrc_bubbles:BubblesArtist2,
        imgsrc_caption:'Montclair College Dance Studio, just outside NYC',
    },
    {
        id: 3,
        title: 'Through the Artist’s Entrance',
        description: 'The artist entrance to the Hayward is normally blocked except when artists are expected to come and go.  It’s not much but the feeling that you get a pass just because you are allowed through here is a nice high if you think you are an outsider.',
        bold_description: '',
        imgsrc_regular:RegularArtist3,
        imgsrc_bubbles:BubblesArtist3,
        imgsrc_caption:'The artist entrance to the Hayward Gallery in London',
    },
]

/* subpage: travel the world*/
export const Travel = [
    {
        id: 1,
        title: 'Traveling the World',
        description: 'I am an adventurous eater, but not obsessively, nor someone who weighs novelty higher than quality and taste.  Part of the reason I care for food so much is because from my mother I got super taste and super nose. Been tested. So, my engagement is high.  This is temporary text. ',
        bold_description: '',
        imgsrc_regular:RegularTravel0,
        imgsrc_bubbles:BubblesTravel1,
    },
]
/* subpage: travel the world: image section*/
export const TravelImg = [
    {
        id: 1,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel11,
    },
    {
        id: 2,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel12,
    },
    {
        id: 3,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel13,
    },
    {
        id: 4,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel21,
    },
    {
        id: 5,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel22,
    },
    {
        id: 6,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel23,
    },
    {
        id: 7,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel31,
    },
    {
        id: 8,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel32,
    },
    {
        id: 9,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel33,
    },
    {
        id: 10,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel41,
    },
    {
        id: 11,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel42,
    },
    {
        id: 12,
        title: 'Traveling the World',
        imgsrc_regular:RegularTravel43,
    },
]

/* subpage: "the delight of food": 
    each ID represents a paragraph containing regular image & bubble image(Carole's design)
*/
export const FoodDelight = [
    {
        id: 1,
        title: 'The Delight of Food',
        description: 'I am an adventurous eater, but not obsessively, nor someone who weighs novelty higher than quality and taste.  Part of the reason I care for food so much is because from my mother I got super taste and super nose. Been tested. So, my engagement is high.',
        bold_description: '',
        imgsrc_regular:RegularFood1,
        imgsrc_bubbles:BubblesFood1,
        imgsrc_caption:'',
    },
    {
        id: 2,
        title: 'The Delight of Food',
        description: 'Who really understands where the depth of human pleasure in food lies? Why should someone able to distinguish 500 smells where another might distinguish 100 make the more variegated smellscape more beautiful or intense? And since food is a cultural product, like painting or architecture, why should the delight one takes be more tied to primitive sensing than to interpretation based on knowledge, history and context. Hence, the importance of plating, venue, company, and nostalgia. ',
        bold_description: '',
        imgsrc_regular:RegularFood2,
        imgsrc_bubbles:BubblesFood2,
        imgsrc_caption:'Yummm! A spoonful of night singers by the Mekong in Laos',
    },
    {
        id: 3,
        title: 'The Delight of Food',
        description: 'Cooking is something I like to do, but not if it involves more than 2 hrs of preparation. Knife skills speeds up work, and so does thinking about what to do where and how to lay out ingredients. Just after leaving MIT I started work on a theory of preparation, partly motivated by cooking. The reason so many of us use cooking as an example of higher situated cognition is that it looks so planful.  There is a recipe, written or learned or formulated on the spot. There are so many ingredients with quantities to measure, timing is important, and of course there is monitoring, adapting and error correction. You can also fail. So, it is a great domain for reflective science. Lately I’ve started to take up woodworking. That gives me some new examples of how we organize space, prepare our work environment, and interactively harness structures and processes in the environment to help us reach our goals. ',
        bold_description: '',
        imgsrc_regular:RegularFood3,
        imgsrc_bubbles:BubblesFood3,
        imgsrc_caption:'Crispy birds on a stick in northern Vietnam',
    },
    {
        id: 4,
        title: 'The Delight of Food',
        description: 'I tend to make richly flavored home cooking dishes. Here (honestly) is a typical dinner. Greek chicken and potatoes. It doesn’t take long, prep is fast and easy and it always gets great reviews from us all. There’s a lovely marinade that is super fast - and I find you can always make more and cook it rather than just marinade it if your in a rush. But baking the lemons with the chicken and potatoes is what makes this so good.  The potatoes keep their potatoey flavor despite the acid because the lemon skins become sweet and combine with their native bitter in a most pleasing way. It’s far more complex than I would have guessed.',
        bold_description: '',
        imgsrc_regular:RegularFood4,
        imgsrc_bubbles:BubblesFood4,
        imgsrc_caption:'Greek chicken and potatoes',
    },
]


export const matchID = {
    1:AboutmePersonally,
    2:ArtistEntrance,
    3:Travel,
    4:FoodDelight,
}