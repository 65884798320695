/*For member card in the Lab*/
import React from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useState, useRef} from 'react';
import Popover from 'react-bootstrap/Popover'
import { FileText } from 'react-feather';
import * as Icon from 'react-feather';


const Styles = styled.div`
  p{
    margin-bottom: 0;
    font-size: 14pt;
  }
  a{   
    text-decoration: underline;
    color: #000;
    opacity: 50%;
  }
  h4{
    margin-top: 3%;
    opacity: 80%;
  }

//abstract link section
  .pub-link-section{
    font-size: 12pt; 
    display: inline-block; 
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .abstract-section{
    display: inline; 
  }
  .link-section{
    display: inline; 
    margin-top: 2rem !important;
  }

  .btn-primary{
    color: #000;
    opacity: 55%;
    background-color:#fff;
    border: none;
    font-family: 'Lato', sans-serif;
    font-size: 12pt;
    padding: 0;
    margin-top: -0.15rem;
  }
  
  .title{
    color: #B30009;
  }
  .title-section{
    display: flex;
  }

  .col-md-1{
    padding:0;
  }
  .col-md-11{
    padding-left:3%;
  }

  .verticalline{
    border-left:1px solid #000;
    padding-left: 2%;
    margin-left:1%;
  }

  @media only screen and (min-width: 620px) {
    .verticalline{
      border-left:1px solid #000;
      padding-left: 2%;
      margin-left:1%;
    }
  }

`;

//control the style of the popover abstract
const PopoverStyle = {
  position: "relative",
  backgroundColor:'#fff', 
  border: '2px solid #000',
  padding: '1rem',
  boxShadow: '5px 5px #B30009',
  fontWeight: '300',
  fontFamily: 'Lato, sans-serif',
  letterSpacing:'1%',
}
 
const FullArticle = {
  display: 'flex',
  justifyContent:'flex-end',
  color: '#B30009',
}

const Divider = {
  border: '0.5px solid #BEBEBE',
  width: '30%',
  marginTop:'3%',
  marginBottom:'3%',
}

const AbstractTitle = {
  display: 'flex',
  justifyContent: 'space-between',
}



function CardPublication(props){
  const [show, setShow] = React.useState(false)
  const popover = (
    <Col xs={12} md={{span: 6, offset: 1}}>
    <div style={PopoverStyle} onMouseOver={()=>setShow(true)} onMouseOut={()=>setShow(false)}>
      <div style={AbstractTitle}>
        <h4>{props.abstract_title}</h4>
        <div style={{cursor:'pointer'}} onClick={()=>setShow(false)}>
        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 30 30" width="36px" fill="#B30009">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
        </svg>
        </div>
      </div>
      <div style={Divider}/>
      <p><strong>Abstract: </strong>
        {props.abstract_body}
      </p>
      <a style={FullArticle} target= "_blank" href={props.pdflink}> Full Article </a>
    </div>
    </Col>
  );


  return(
    <Styles>
      <FontStyle>
        <br/>
        <div className="verticalline">
          <p className="title">{props.title}</p>
          <div className="pub-link-section">
            {props.abstract_body != 0 &&
              <div className="abstract-section" onMouseOver={()=>setShow(true)} onMouseOut={()=>setShow(false)}>
                <OverlayTrigger trigger= "hover" placement="bottom" overlay={popover} show={show}>
                  <Button >Abstract</Button>
                </OverlayTrigger>
              </div>
            }
              &nbsp; &nbsp;  
              {props.pdflink != 0 && <a className="link-section" target="_blank" href={props.pdflink}> PDF </a> }
            </div>
          </div>
          <br/>
      </FontStyle>
    </Styles>
  );
}

export default CardPublication;
