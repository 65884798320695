/*
  Home landing page: 
  each section on the home page is itself a component to controll the responsiveness
  
  - If want to change the header section: navigate to 
    components -> Header.js
  - If want to change the currently working on section: navigate to 
    components -> CardElement -> Home_CurrentlyWorking.js
  - If want to change the research section: navigate to 
  components -> CardElement -> Home_CardResearch.js
  - If want to change the course section: navigate to 
  components -> CardElement -> Home_CardCourse.js

  more instructions over those documents
  
*/


import React from 'react';
import styled from 'styled-components';

import Header from './components/CardElement/Home_Header';
import CardCourseHome from './components/CardElement/Home_CardCourse';
import CardResearchHome from './components/CardElement/Home_CardResearch';
import CurrentlyWorkingHome from './components/CardElement/Home_CurrentlyWorking';

import FontStyle from './FontStyles';

const Styles = styled.div`
  a{
    text-decoration: none;
    color: #000;
  }

//desktop spacing
  .margins{
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

//mobile spacing
  @media only screen and (max-width: 400px) {
    .margins{
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }
`;





function Home () { 

  return(   
     
  <Styles>
    <FontStyle>
      <React.Fragment> 
          <Header />

          {/* for the currently working on section at home page */}
          <div className="margins">
            <CurrentlyWorkingHome/>
          </div>

          {/* for the research section at home page */}
          <div className="researchtopic">
            <CardResearchHome />
          </div>

          {/* for the course section at home page */}
          <div className="margins">
            <CardCourseHome />
          </div>
      
    </React.Fragment>
    
    </FontStyle>
    </Styles>
  );
}

export default Home;