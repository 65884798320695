import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { ThemeContext } from '../App';

const Styles = styled.div`
    .dropdown-toggle{
        border: 0.5px solid #DCDCDC;
        background-color: #fff !important;
        color: #000 !important;
    }
    a:active{
        background-color: #B30009;
    }

`;

function getBold(currentID, dropdownID) {
    if (currentID === dropdownID) {
        return { 'font-weight': '700' }
    }
    return {}
}

function PubDropdown(props) {
    const currentContext = useContext(ThemeContext);
    const HandleClick = (e) => {
        e.preventDefault();
        currentContext.setcurrentTopic(e.target.id);
    }
    
    return (
        <Styles>
            <DropdownButton title={props.title}>
                {props.dropdownItemList.map((item, index) => 
                    <div item={index}>
                        <Dropdown.Item id={item} onClick={HandleClick} style={getBold(currentContext.currentTopic, item)}>{item}</Dropdown.Item>
                    </div> 
                )}
            </DropdownButton>
        </Styles>
    )
}



export default PubDropdown;