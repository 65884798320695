import React from "react";
import { a, BrowserRouter as Router} from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import FontStyle from '../FontStyles';
import Logo from '../a_media/images/navbar/Logo.png';
import FooterImg from '../a_media/images/navbar/FooterImg.png';
import Contactinfo from '../a_media/images/navbar/Contactinfo.png';
import Header from '../a_media/images/navbar/Header.png';


const Styles = styled.div`
  .navbar {   
    // margin-top: 15%;
    // background-color: #000;
    //background-image: url('../a_media/images/navbar/FooterImg.png');
    padding-left: 2.5rem;
    // padding-right: 2.5rem;
    padding-bottom:1.5em;
   
  }
  .footer-navi{
    position: relative;
    left: 0;
    bottom: 0;
    width: 100vw;
  }

  .navbar a{
    text-decoration: none;
    color: white;
  }

  .navbar a:hover{
    text-shadow: 1px 0px 0px white;
  }

  .nav-item{
    font-family: 'Lato', sans-serif;
    font-size: 11pt;
    padding-top:1.5em;
    padding-bottom:2em;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-brand{
    display: flex;
    justify-content: flex-end;
  }

`;
//style of background image of navi
var navi_bg = {
  backgroundImage: `url(${Header})`
}

const Footer = () => (
    <Styles>
    <FontStyle>
      <Router>
        <div className="footer-navi">
          <Navbar variant="dark" expand="lg" style={navi_bg}>
          
            <Navbar.Brand href="/"><img src={Logo} width="100px"/></Navbar.Brand> 
            <Navbar.Brand><img src={Contactinfo} width="150px"/></Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item><a href="/">HOME</a></Nav.Item>
                <Nav.Item><a href="/research">RESEARCH</a></Nav.Item>
                <Nav.Item><a href="/lab">LAB</a></Nav.Item>
                <Nav.Item><a href="/publications">PUBLICATIONS</a></Nav.Item>
                <Nav.Item><a href="/teaching">TEACHING</a></Nav.Item>
                <Nav.Item><a href="/speaker_consultant">SPEAKER/CONSULTANT</a></Nav.Item>
                <Nav.Item><a href="/about">ABOUT</a></Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Router>
    </FontStyle>
  </Styles >
 
)

export default Footer;