/*img on the left, text on the right*/
import React, { Component } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FontStyle from '../../FontStyles';


const Styles = styled.div`
    .row{
        margin-top: 13rem;
    }
    a:hover{
        text-decoration: none;
    }
    .btn-style{
        position: relative;
    }
    .btn-primary{
    position: absolute;
    border-radius: 0px;
    background-color: #000;
    border: none;
    width: 160px;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    z-index:1;
    }
    
    .borderline{
    border: 2px solid #000;
    width: 160px;
    height: 40px;
    z-index:0;
    position: absolute;
    margin-top: 1.5%;
    margin-left: 1.5%;
    }
`;

/*img on the left, text on the right*/
export function ImgLeft(props){
  return(
    <FontStyle>
        <Styles>
            <Row>
                <Col xs={12} md={{offset:1, span:4}}>
                    <img src={props.imgsrc_regular} width="90%"></img>
                </Col>
                <Col xs={12} md={{offset:1, span:5}}>
                    <h3>{props.title}</h3>
                    <p>{props.description}
                    {props.bold_description}</p>
                    <div className="btn-style">
                        <Button href={props.link}>Learn More</Button>
                        <div className="borderline"/>
                  </div>
                </Col>
            </Row>
        </Styles>
    </FontStyle>
  );
}

/*img on the right, text on the left*/
export function ImgRight(props){
    return(
    <FontStyle>
      <Styles>
          <Row>
              <Col xs={12} md={{offset:1, span:5}}>
                  <h3>{props.title}</h3>
                  <p>{props.description}
                  {props.bold_description} </p>
                  <div className="btn-style">
                    <Button href={props.link}>Learn More</Button>
                    <div className="borderline"/>
                  </div>
              </Col>
              <Col xs={12} md={{offset:1, span:4}} className="order-first order-md-last">
                  <img src={props.imgsrc_regular} width="90%"></img>
              </Col>
          </Row>
      </Styles>
    </FontStyle>
    );
  }

// export default ImgLeft;

