/*img on the left, text on the right*/
import React, { Component } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import FontStyle from '../../FontStyles';


const Styles = styled.div`
    .row{
        margin-top: 6rem;
    }
    p{
        margin-top: 2rem;
    }
    .container{
        padding-left:0;
        padding-left:0;
    }
`;

/*img on the left, text on the right*/
export function ImgLeft(props){
  return(
    <FontStyle>
    <Styles>
        <Container>
        <Row>
            <Col xs={12} md={{offset:1,span:6}}>
                <img src={props.imgsrc_regular} width="90%"></img>
            </Col>
            <Col xs={12} md={{span:5}}>
                {/* <h4>{props.title}</h4> */}
                <p>{props.description}</p>
                <strong>{props.bold_description}</strong>
            </Col>
        </Row>
        </Container>
    </Styles>
    </FontStyle>
  );
}

/*img on the right, text on the left*/
export function ImgRight(props){
    return(
    <FontStyle>
      <Styles>
      <Container>
          <Row>
              <Col xs={12} md={{offset:1, span:5}}>
                  {/* <h2>{props.title}</h2> */}
                  <p>{props.description}</p>
                  <strong>{props.bold_description} </strong>
              </Col>
              <Col xs={12} md={{offset:1,span:5}} className="order-first order-md-last">
                  <img src={props.imgsrc_regular} width="100%"></img>
              </Col>
          </Row>
        </Container>
      </Styles>

      </FontStyle>
    );
  }

// export default ImgLeft;

