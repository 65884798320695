/*For About me 
secondary pages: Working for Money vs. Fun vs. Meaning
   -   Theme changes when click from different About me landing pages
        From carole's(bubbles) design: background--black; text -- white
        From regular design: background--white; text -- black
*/
import React, {useEffect,useState} from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import BreadCrumb from '../../components/Breadcrumb';

import WorldBank from '../../a_media/about_img/z_subpage_wff.png';

const Styles = styled.div`
    .section{
        margin-bottom: 10rem;
    }
    a:hover{
        text-decoration: none;
    }

    .text-section{
        padding-left:15%;
        padding-right:15%;
        margin-top: 5rem;
        max-width:100%;
    }
    .para-text-section{
        max-width:100%;
    }


    @media only screen and (max-width: 800px) {
        
        .text-section{
            padding-left:0%;
            padding-right:0%;
        }
        .para-text-section{
            max-width:100%;
        }
    }

`;

const Darkbtn = styled.div`
    .btn-spacing{
        margin-top:15rem;
        padding-bottom:5rem;
        background-color:#000000;
    }
    i{
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        color:#fff;
    }
    .navi-text i{
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 500;
        color:#c4c4c4;
    }
    .navi-text{
        margin-top:4rem;
    }
    //button css
    .btn-style{
    position: relative;
    }
    .btn-primary{
    position: absolute;
    border-radius: 0px;
    background-color: #B30009;
    border: none;
    width: 100px;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    z-index:1;
    }

    .borderline{
    border: 2px solid #FFFFFF;
    width: 100px;
    height: 40px;
    z-index:0;
    position: absolute;
    margin-top: 3%;
    margin-left: 3%;
    }

    .pre-btn{
        position: relative;
    }
    .pre-btn .borderline{
        margin-top: 2%;
        margin-left: 2%;
    }
    .next-btn {
        position: relative;
        left:65%;
    }
    @media only screen and (max-width: 800px) {
        .spacing{
            margin-top:3rem;
        }
        .btn-spacing{
            margin-top:5rem;
            padding-bottom:2rem;
            background-color:#000000;
        }
        .container{
            padding-left:0;
            padding-right:0;
        }
        .next-btn {
            position: relative;
            left:28%;
        }
        .greenbar{
            position: absolute;
            width: 6%;
            right: 3px;
            top: -20%;
        }
    }
`;
function DarkBtngroup(){
    return (
        <Darkbtn>
            <FontStyle>
                <React.Fragment>
                <Row className="btn-spacing">
                    {/* <div className="btn-section"> */}
                        <Col xs={6} md={6}>
                            {/* <div className=""> */}
                                <div className="pre-btn btn-style">
                                    <Button href={'/about'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="40" y1="12" x2="5" y2="12"></line>
                                            <polyline points="12 19 5 12 12 5"></polyline>
                                        </svg> &nbsp;&nbsp;&nbsp;
                                        Back
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div> */}
                                <div className="navi-text">
                                    <i>to About Me</i>
                                </div>
                            
                        </Col>
                        <Col xs={6} md={{offset:2, span:4}}>
                            {/* <div > */}
                            {/* <div className=""> */}
                                <div className="next-btn btn-style">
                                    <Button href={'/about/videogame'}>
                                        Next &nbsp;&nbsp;&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="0" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div>     */}
                                <div style={{textAlign:'right'}} className="navi-text">
                                    <i>to Are We Living in a 3D Video Game?</i>
                                </div>
                            {/* </div> */}
                      
                        </Col>
                        {/* </div> */}
                </Row> 
                </React.Fragment>
            </FontStyle>
        </Darkbtn>
    )
}

const Lightbtn = styled.div`
.btn-style{
    margin-top: 8rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-item: center;
}
.btn-primary{
    position: absolute;
    border-radius: 0px;
    background-color: #000;
    border: none;
    width: 160px;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    z-index:1;
}

.borderline{
    border: 2px solid #000;
    width: 160px;
    height: 40px;
    z-index:0;
    position: absolute;
    margin-top: 0.5%;
    margin-left: 1%;
}
@media only screen and (max-width: 800px) {
    .borderline{
        border: 2px solid #000;
        width: 160px;
        height: 40px;
        z-index:0;
        position: absolute;
        margin-top: 1.5%;
        margin-left: 3%;
    }
}
`;
function LightBtngroup(){
    return (
        <Lightbtn>
            <FontStyle>
                <React.Fragment>
 
                    <div className="btn-style">
                        <Button href='/about'>Back to About Me</Button>
                        <div className="borderline"/>
                    </div>
                    <div className="section"/>
                   
                </React.Fragment>
            </FontStyle>
        </Lightbtn>
    )
}


export const AboutWorkFor = () => {
    const [bgcolor, setBgcolor] = useState("#000000");
    const [textcolor, setTextcolor] = useState("#000000");
    const [btnsection, setBtnsection] = useState(<LightBtngroup/>);

    useEffect(()=>{
        let randomnum = parseInt(window.sessionStorage.getItem("randomnum"));
        if (randomnum <= 4 || (6 < randomnum && randomnum <= 8)){
            // console.log("category1", randomnum);
            setTextcolor('#ffffff');
            setBgcolor('#000000');
            setBtnsection(<DarkBtngroup/>);
        }else if (4 < randomnum && randomnum <= 6){
            // console.log("category2", randomnum);
            setTextcolor('#000000');
            setBgcolor('#ffffff');
            setBtnsection(<LightBtngroup/>);
        }
    },[]);

    return(
    <Styles style={{backgroundColor: bgcolor}}>
        <FontStyle>
            <React.Fragment>
            <br/><br/><br/><br/>
                <Container>
                    <Row>
                        <BreadCrumb 
                            link1={'/'} link2 ={'/about'} secondlevel={'About Me'}
                            current={'Working for Money vs. Fun vs. Meaning'}
                        />
                    </Row>
                        <h2 style={{color:textcolor}}>Working for Money vs. Fun vs. Meaning</h2>
                        <img src={WorldBank} alt="world bank" width="100%"/>
                        <p style={{fontWeight:'500',color:textcolor}}>The World Bank in Washington DC, as it looks now. Copyright: World Bank / Grant Ellis </p>
                        
                        <div style={{color:textcolor}} className="text-section">
                            <p>Since 1976 I have been institutionalized. Were it not for an exciting foray from 1998 to 2002 working 
                                as a long-term consultant at the World Bank -- as chief information architect for their internet --  
                                I would never know what it feels like to:</p>
                            
                            <ul>
                                <li>earn a big salary - my WB salary was approx. 3.5 times my UCSD salary;</li>
                                <li>work from 8-5,</li>
                                <li>wear a jacket, proper trousers and dress shoes most days. </li>
                            </ul>

                            <p>Because my job involved redesigning from scratch the WB external site, and then their 350K page 
                            intranet, I had a chance to repeatedly meet the president and senior execs of this huge organization. 
                            I met state ministers, heads of corporations, and I travelled a fair bit.  I was leading a certain 
                            definition of a successful life. Yet, when I was offered a permanent position, I had no hesitation in 
                            saying no? Why not trade academia for a much richer institution, you ask? </p>
                            
                            <br/><br/>

                            <h4>Here’s my reason and the excuse for sharing this autobiographical fragment.</h4>
                            <p>At the end of one big year, we had redesigned a huge site to some acclaim. It was rush rush get-it-done. 
                                The occasion was the annual meetings with the IMF and high-level country officials. 
                                Because of the excitement it was fun. But later I had this realization.</p>
                            
                            <br/><br/>

                            <h4>Where’s the Meaning?</h4>
                            <p>It was fun because of comradery, and everyone pulling together, staying late and working on something 
                                that seemed important. But really? All our efforts were for contrived deadlines. Agreed, this one was 
                                for an important international meeting.  But so many of our other deadlines were created solely by 
                                the people higher up. And even this one, did it really matter if visitors saw only modest changes to 
                                last year’s version?  Would they even remember? Now I always ask myself: When I’m old will I look back 
                                and say “I enriched my life by doing that.”? Maybe transforming the intranet once would qualify, but 
                                surely not doing it multiple times.</p>
                                <br/><br/>
                            <p>I always come back to the phrase: </p>
                            <h3 style={{textAlign:'center'}}> "Seek, above all, a game worth playing.” </h3>
                            <p>by Robert de Ropp in that hippy turn on book “The Master Game”.</p>
                        </div>
                        {btnsection}  
                    </Container>

            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}

