/*navigation bar */
import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import FontStyle from '../FontStyles';
import Logo from '../a_media/images/navbar/Logo.png';
import Header from '../a_media/images/navbar/Header.png';
import Researchinfo from '../content/Researchinfo.js';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Contactinfo from '../a_media/images/navbar/Contactinfo.png';
import Mobilebottom from '../a_media/images/navbar/Mobilebottom.png';
import ResearchMap from '../content/Research/research_map.json';

const Styles = styled.div`

  .navbar_position{
    position: absolute;
    top:0;
    width:100vw; 
    z-index:3;
  }

  .navbar{  
    font-family: 'Lato', sans-serif;
    background-color: #000;
  }

  .navbar-brand img{
    width: 100px;
  }

  .navbar a{
    text-decoration: none;
    color: white;
    font-size: 11pt;
    padding-top:1.5em;
    padding-bottom:1.5em;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar a:hover{
    text-shadow: 1px 0px 0px white;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1) !important;
    border: none;
    padding-right:20%:
  }

  .dropdown-menu{
    background-color: #222;
  }

  .dropdown-menu a:hover{
    background-color: #000;
  }
  .dropdown-menu a:active{
    background-color: #000;
  }

  .dropdown-item{
    padding: 0;
  }

  //control the position of navitem except research
  .othernavitem {
    padding-top:1.25em;
    padding-bottom:1.5em;
    padding-right: 1.5rem;
  }


  //mobile styling
  @media only screen and (max-width: 620px) {
    .navbar a{
      text-decoration: none;
      color: white;
      font-size: 13pt;
      padding-left: 1rem;
    }
    .navbar{  
      padding-bottom:0;
    }

    .navbar-collapse{  
      height: 90vh;
    }

    .othernavitem {
      padding-top:1em;
      padding-bottom:1em;
      padding-right: 1.5rem;
    }
    .dropdownitem{
      padding-top:0.5em !important;
      padding-bottom:0.5em !important;
    }
    .mobilebottom-img{
      margin-left:-20px !important;
      margin-right:-20px !important;
    }
  }
`;

//texture style of background image of navi
var navi_bg = {
  backgroundImage: `url(${Header})`
}

let current_pathname = window.location.pathname.split("/");

//style of research dropdown
function getBold(currentID,dropdownID){
  if (current_pathname[1] === "research"){
    currentID = currentID ? currentID : ""
    if (currentID === dropdownID) {
      return { 'background-color': '#000000' }
    }
  }
  return {}
}

//responsive detector for mobile style change
function useResponsive() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return width;
}

const OnclickStyle = {
  'backgroundColor': "#fff",
  'marginLeft':"-1.5rem",
  'marginRight':"-1.55rem",
  'paddingLeft':"1.5rem",
}

const DesktopStyle = {
  // ...OnclickStyle,
  'backgroundColor': "#fff",
  'paddingTop':"2.3rem",
  'paddingBottom':"2.3rem",
  'color':"#000",
  'fontWeight':"700",
}

const MobileStyle = {
    'color':"#000",
    'fontWeight':"700",
}

//contact info at the end of the menu list for mobile
const MobileContactinfo = () =>{
  return (
    <React.Fragment> 
      <img style={{marginTop:"16vh"}} src={Mobilebottom} width = "100%" />
    </React.Fragment>
  )
}

const ResearchDropdown = Object.keys(ResearchMap);

function Navibar(){
  const width = useResponsive(); //get the width of the screen
  const breakpoint = 1199; //become a menu icon when < than breakpoint
  const [bgstyle, setStyle] = useState(DesktopStyle);
  const [showstatus, setShowstatus] = useState(false); // for contactinfo on mobile
  const [onselectstatus, setOnselectstatus] = useState(false); //for auto collapse 
  
  //make sure the styles (e.g background of the chosen page) stay on after refreshing the page
  const pathdetector = (currentpath) =>{
    let idNum = "";
    if (currentpath == ""){
      idNum = "1";
    }else if (currentpath == "research"){
      idNum = "2";
    }else if (currentpath == "lab"){
      idNum = "3";
    }else if (currentpath == "publications"){
      idNum = "4";
    }else if (currentpath == "teaching"){
      idNum = "5";
    }else if (currentpath == "speaker_consultant"){
      idNum = "6";
    }else if (currentpath == "about"){
      idNum = "7";
    }
    return idNum;
  }

  //navbar item ID: detect the existing ID for refreshing; set new ID when onclick
  const [NavID, setNavID] = useState(pathdetector(current_pathname[1])); 

//decide whether to use mobile style or desktop style
  useEffect(() => {
    if (NavID != null){
      if (width < breakpoint){
        setStyle(MobileStyle);
      } else{
        setStyle(DesktopStyle);
      } 
    }
    
    //decide whether to show contact info based on size of screen
    if (width < 550){
      setShowstatus(true);
    } else{
      setShowstatus(false);
    } 
  },[width,NavID])

  //handle click on navbar item
  const HandleClick = (e) => {
    const currentNavID = e.target.id;
    if (currentNavID !== '2'){
      setNavID(currentNavID);
    }else{
      setNavID("");
    }
    if (width < breakpoint){
      setOnselectstatus(!onselectstatus);
    }
  }

//background style with white block
  function getBg(currentID, clickedID){
    if (currentID === clickedID){
      return bgstyle
    } else {
      return null
    }
  }

  const outer_helper = (curren_id) => {
    return curren_id == NavID &&  bgstyle != DesktopStyle ? OnclickStyle : null
  }
  
return(
  <Styles>
    <FontStyle>
      <div className="navbar_position">
        <Navbar  expanded={onselectstatus} expand="xl" style={navi_bg} variant="dark" > 
          <Navbar.Brand href="/"><img src={Logo}/></Navbar.Brand>
          <Navbar.Toggle onClick={()=>setOnselectstatus(!onselectstatus)} aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item>
                <div className="othernavitem" style={outer_helper("1")}>
                  <Link to="/" id = "1" style={getBg("1", NavID)} onClick={HandleClick}>HOME</Link>
                </div>
              </Nav.Item>
            
                <NavDropdown id="2" title="RESEARCH">
                <NavDropdown.Item className="dropdownitem" href={"/research"} style={getBold(current_pathname[2], "")}>
                  All
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {Object.values(ResearchDropdown).map((ele, index) => {
                  return (
                    <div ele={index}>
                      <NavDropdown.Item className="dropdownitem" href={"/research/" + encodeURIComponent(ele)} style={getBold(decodeURIComponent(current_pathname[2]), decodeURIComponent(ele))}>
                        {ele}
                      </NavDropdown.Item>
                    </div>
                  );
                })
                }
                </NavDropdown>
              <Nav.Item>
                <div className="othernavitem" style={outer_helper("3")}>
                  <Link to="/lab" id="3" style={getBg("3",NavID)}  onClick={HandleClick}>LAB</Link>
                </div>
              </Nav.Item>
              <Nav.Item>
                <div className="othernavitem" style={outer_helper("4")}>
                  <Link to="/publications" id="4" style={getBg("4",NavID)}  onClick={HandleClick}>PUBLICATIONS</Link>
                </div>
              </Nav.Item>
              <Nav.Item>
                <div className="othernavitem" style={outer_helper("5")}>
                  <Link to="/teaching" id="5" style={getBg("5",NavID)}  onClick={HandleClick}>TEACHING</Link>
                </div>
              </Nav.Item>
              <Nav.Item>
                <div className="othernavitem" style={outer_helper("6")}>
                  <Link to="/speaker_consultant" id="6" style={getBg("6",NavID)} onClick={HandleClick}>SPEAKER/CONSULTANT</Link>
                </div>
              </Nav.Item>
              <Nav.Item>
                <div className="othernavitem" style={outer_helper("7")}>
                  <Link to="/about" id="7" style={getBg("7",NavID)}  onClick={HandleClick}>ABOUT ME</Link>
                </div>
              </Nav.Item>
     
              </Nav>
              <div className="mobilebottom-img">
                {/* <div>{showstatus}</div> */}
                {showstatus ? <MobileContactinfo/> : null}
              {/* </Nav.Item> */}
              </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </FontStyle>
  </Styles >
  )
}

export default Navibar;