 /*
  Teaching landing page: 
  - Use CardCourse to format how each course card looks
  - Render Courseinfo for ungrad courses and grad course
  
  If want to change course info, navigate to content --> Courseinfo.js
*/

import React,{useState, useEffect} from 'react';
import styled from 'styled-components';

import {UngradCourse, GradCourse} from './content/Courseinfo';
import CardCourse from './components/CardElement/CardCourse';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FontStyle from './FontStyles';
import Banner from './a_media/course_img/teaching_banner.png';
import MBanner from './a_media/course_img/mobile_teaching_banner.png';


/*course card style */
const Styles = styled.div`
  a{
    text-decoration: none;
    color: #000;
  }
  .banner{
    width: 100vw !important;
    max-height:450px;
    object-fit: cover;
  }

.course{
  position: relative;
}
.section{
  margin-bottom: 10rem;
}

.title{
  position: relative;
  margin-top: 5%;
  margin-bottom: -5%;
  margin-left: 2%;
}
.container{
  padding-left:5vw;
  padding-right:5vw;
}
.col-md-6{
  padding-left:3vw;
  padding-left:3vw;
}

@media only screen and (max-width: 400px) {
  .banner{
    width: 100vw !important;
  }
  .title{
    position: relative;
    // margin-top: 40%;
    margin-bottom: -8%;
    margin-left: -5%;
  }
  .container{
    padding-left:30pt;
    padding-right:30pt;
  }
  .col-12{
    padding-left:0;
    padding-left:0;
  }
}

`;


//responsive detector for mobile style change
function useResponsive() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return width;
}


export const Teaching = () => {
  const width = useResponsive(); //get the width of the screen
  const breakpoint = 620;
  const [bannerimg, setBannerimg] = useState(Banner);

  useEffect(() => {
    if (width < breakpoint){
      setBannerimg(MBanner);
    }else{
      setBannerimg(Banner);
    }
  },[width])

    return(
      <Styles>
        <FontStyle>
          <React.Fragment>
          <br/><br/><br/>
          <img className="banner" src={bannerimg} alt="banner image"/>
          
           <Container>
           <h2 className="title">Teaching</h2>
            {/* <h3>Undergraduate Courses</h3> */}
            <Row className="course">
              {UngradCourse.map((ele, index) => {
                return (
                  // index % 2 == 0 ?
                  <Col xs={12} md={6}>
                    {/* <Link to={"/teaching/undergrad/" + ele.id + "/" + ele.coursecode}> */}
                    {/* <CardCourse canvas_link={ele.canvas_link} /> */}
                      <CardCourse
                      title={ele.title} quarter={ele.quarter} time={ele.time} location={ele.location} 
                      thumbnailimg={ele.thumbnailimg} description={ele.description} canvas_link={ele.canvas_link}
                      link={"/teaching/undergrad/" + ele.id + "/" + ele.coursecode}
                      />
                    {/* </Link> */}
                    
                  </Col>
                //   :<Col xs={12} md={6}>
                //     <CardCourse
                //     title={ele.title} quarter={ele.quarter} time={ele.time} location={ele.location} 
                //     imgsrc={ele.imgsrc} description={ele.description} canvas_link={ele.canvas_link}
                //     link={"/teaching/undergrad/" + ele.id + "/" + ele.coursecode}
                //     />
                // </Col>
                );
              })}
            
            </Row>
            {/* <h3>Graduate Courses</h3> */}
            <Row className="section">
              {GradCourse.map((ele) => {
                return (
                  <Col xs={12} md={6}>
                    {/* <Link to={"/teaching/grad/" + ele.id + "/" + ele.coursecode}> */}
                      <CardCourse
                      title={ele.title} quarter={ele.quarter} time={ele.time} location={ele.location} 
                      thumbnailimg={ele.thumbnailimg} description={ele.description}
                      canvas_link={ele.canvas_link} 
                      link={"/teaching/grad/" + ele.id + "/" + ele.coursecode}
                      />
                    {/* </Link> */}
                  </Col>
                );
              })}
            </Row>
            </Container>
          </React.Fragment>
        </FontStyle>
      </Styles>
    );
}
