/*For About me 
    secondary pages: Working for Money vs. Fun vs. Meaning
*/
import React, {useEffect,useState} from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import BreadCrumb from '../../components/Breadcrumb';

import VideoGame1 from '../../a_media/about_img/z_subpage_videogame1.png';
import VideoGame2 from '../../a_media/about_img/z_subpage_videogame2.png';


const Styles = styled.div`
    .section{
        margin-bottom: 10rem;
    }
    a:hover{
        text-decoration: none;
    }

    .text-section{
        padding-left:15%;
        padding-right:15%;
        margin-top: 5rem;
        max-width:100%;
    }
    .para-text-section{
        max-width:100%;
    }


    @media only screen and (max-width: 800px) {
        
        .text-section{
            padding-left:0%;
            padding-right:0%;
        }
        .para-text-section{
            max-width:100%;
        }
    }

`;

const Darkbtn = styled.div`
    .btn-spacing{
        margin-top:15rem;
        padding-bottom:5rem;
        background-color:#000000;
    }
    i{
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        color:#fff;
    }
    .navi-text i{
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 500;
        color:#c4c4c4;
    }
    .navi-text{
        margin-top:4rem;
    }
    //button css
    .btn-style{
    position: relative;
    }
    .btn-primary{
    position: absolute;
    border-radius: 0px;
    background-color: #B30009;
    border: none;
    width: 100px;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    z-index:1;
    }

    .borderline{
    border: 2px solid #FFFFFF;
    width: 100px;
    height: 40px;
    z-index:0;
    position: absolute;
    margin-top: 3%;
    margin-left: 3%;
    }

    .pre-btn{
        position: relative;
    }
    .pre-btn .borderline{
        margin-top: 2%;
        margin-left: 2%;
    }
    .next-btn {
        position: relative;
        left:65%;
    }
    @media only screen and (max-width: 800px) {
        .spacing{
            margin-top:3rem;
        }
        .btn-spacing{
            margin-top:5rem;
            padding-bottom:2rem;
            background-color:#000000;
        }
        .container{
            padding-left:0;
            padding-right:0;
        }
        .next-btn {
            position: relative;
            left:28%;
        }
        .greenbar{
            position: absolute;
            width: 6%;
            right: 3px;
            top: -20%;
        }
    }
`;
function DarkBtngroup(){
    return (
        <Darkbtn>
            <FontStyle>
                <React.Fragment>
                <Row className="btn-spacing">
                    {/* <div className="btn-section"> */}
                        <Col xs={6} md={6}>
                            {/* <div className=""> */}
                                <div className="pre-btn btn-style">
                                    <Button href={'/about'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="40" y1="12" x2="5" y2="12"></line>
                                            <polyline points="12 19 5 12 12 5"></polyline>
                                        </svg> &nbsp;&nbsp;&nbsp;
                                        Back
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div> */}
                                <div className="navi-text">
                                    <i>to About Me</i>
                                </div>
                            
                        </Col>
                        {/* <Col xs={6} md={{offset:2, span:4}}>
                         
                                <div className="next-btn btn-style">
                                    <Button href={'/about/artistentrance'}>
                                        Next &nbsp;&nbsp;&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="0" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                                <div style={{textAlign:'right'}} className="navi-text">
                                    <i>to Are We Living in a 3D Video Game?</i>
                                </div>                     
                        </Col> */}
                        {/* </div> */}
                </Row> 
                </React.Fragment>
            </FontStyle>
        </Darkbtn>
    )
}

const Lightbtn = styled.div`
.btn-style{
    margin-top: 8rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-item: center;
}
.btn-primary{
    position: absolute;
    border-radius: 0px;
    background-color: #000;
    border: none;
    width: 160px;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    z-index:1;
}

.borderline{
    border: 2px solid #000;
    width: 160px;
    height: 40px;
    z-index:0;
    position: absolute;
    margin-top: 0.5%;
    margin-left: 1%;
}
@media only screen and (max-width: 800px) {
    .borderline{
        border: 2px solid #000;
        width: 160px;
        height: 40px;
        z-index:0;
        position: absolute;
        margin-top: 1.5%;
        margin-left: 3%;
    }
}
`;
function LightBtngroup(){
    return (
        <Lightbtn>
            <FontStyle>
                <React.Fragment>
 
                    <div className="btn-style">
                        <Button href='/about'>Back to About Me</Button>
                        <div className="borderline"/>
                    </div>
                    <div className="section"/>
                   
                </React.Fragment>
            </FontStyle>
        </Lightbtn>
    )
}

const AboutVideo = () => {
    const [bgcolor, setBgcolor] = useState("#000000");
    const [textcolor, setTextcolor] = useState("#000000");
    const [btnsection, setBtnsection] = useState(<LightBtngroup/>);

    useEffect(()=>{
        let randomnum = parseInt(window.sessionStorage.getItem("randomnum"));
        if (randomnum <= 4 || (6 < randomnum && randomnum <= 8)){
            // console.log("category1", randomnum);
            setTextcolor('#ffffff');
            setBgcolor('#000000');
            setBtnsection(<DarkBtngroup/>);
        }else if (4 < randomnum && randomnum <= 6){
            // console.log("category2", randomnum);
            setTextcolor('#000000');
            setBgcolor('#ffffff');
            setBtnsection(<LightBtngroup/>);
        }
    },[]);

    return(
    <Styles style={{backgroundColor: bgcolor}}>
        <FontStyle>
            <React.Fragment>
            <br/><br/><br/><br/>
                <Container>
                    <Row>
                        <BreadCrumb 
                            link1={'/'} link2 ={'/about'} secondlevel={'About Me'}
                            current={'Are We Living in a 3D Video Game?'}
                        />
                    </Row>
                
                    <h2 style={{color:textcolor}}>Are We Living in a 3D Video Game?</h2>
                    <img src={VideoGame1} alt="forty jumps" width="100%"/>
                    <p style={{fontWeight:'500',color:textcolor}}>In 'forty jumps,' scientists model scales of quarks to quasars</p>

                    <div style={{color:textcolor}} className="text-section">
                        <p>One version of the game model of human life - one I confess I occasionally believe - holds that we 
                            are all ‘ancients’ who defeat boredom by incarnating in a form of our choosing and then play out 
                            our days in a dense 3D simulation.  The hardest thing about being a billion years old is finding 
                            things to care about. You must create interest. Even monks find it lethally boring to meditate day 
                            after day for years. Twelve years is the crisis point you’re often told. So how to break the tedium 
                            of profound sameness? Drop down to Earth, or... It’s not a big commitment.  A century for a 
                            year-billionaire is equivalent to just 5 mins of a human life. Even pain can be interesting. 
                            How many genuinely different lives are there anyway?  Five hundred?  Really? Still, because past 
                            decisions affect our future path, there’s always a complex branching decision tree to manage. 
                            So even after 50 lives you still might be interested. It helps that it’s addictive to unlock, 
                            upgrade and master skill trees.</p>
                        
                        <p>To give credibility to the video model some physicists have pointed to the gaps in space time. There 
                        is a huge region of empty space-time between quarks and protons, then between protons and proteins, 
                        and so on up the ladder of being, past humans to the earth then to planets, galaxies and some would 
                        say multiple universes.  If most of our universe is empty how much computation is actually needed to 
                        render a rich world for us?</p>
                    
                        <br/><br/>
                    
                        <h3>What’s the video game got to do with travel?</h3>
                    
                    
                        <Row>
                            <Col xs={12} md={6}>
                                <img src={VideoGame2} alt="The grand Bazaar Istanbul" width="100%"/>
                                <p style={{fontWeight:'500'}}>The grand Bazaar Istanbul.</p>
                            </Col>
                            <Col xs={12} md={6}>
                            <div style={{color:textcolor}} className="para-text-section">
                                <p>The more you travel the more you see recurring patterns. I love bazaars. But honestly, after seeing 
                                ten of the world’s biggest bazaars is there that much difference from one culture to the next? 
                                The people, their costume and some of the color schemes and cultural preferences in interior 
                                design are somewhat different. But aside from the occasional surprise fish, or unique spice or 
                                vegetable we’re so delighted to see in food travel TV, what is different?  It’s reminiscent of 
                                <span style={{ color: '#FF3A3A' }}>  Potemkin villages </span>or the outdoor set at 
                                <span style={{ color: '#FF3A3A' }}> Universal Studios</span>. With a few ingredients and clever 
                                angles and combinatorics scenes seem quite different.  At a deeper level, though, how many 
                                classifiers are needed?</p>
                                </div>
                            </Col>   
                        </Row>  

                    </div>
                    {btnsection}  
                </Container>     
                
            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}

export default AboutVideo;

