import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Speakerinfo, Consultantinfo} from './content/Speakerinfo';
import {ImgLeft} from './components/CardElement/AboutMe_nobtn';

import { Container, Row, Col } from 'react-bootstrap';


import Button from 'react-bootstrap/Button';
import FontStyle from './FontStyles';
// import * as Icon from 'react-feather';

import Speakerbanner from './a_media/speakerconsultant_img/speaker_banner.png';
import Consultantbanner from './a_media/speakerconsultant_img/consultant_banner.png';
import Previousclient from './a_media/speakerconsultant_img/previousclient.png';
import ArrowLeft from './a_media/speakerconsultant_img/Arrowleft.png';
import ArrowRight from './a_media/speakerconsultant_img/Arrowright.png';
import LineLeft from './a_media/speakerconsultant_img/lineleft.png';
import LineRight from './a_media/speakerconsultant_img/lineright.png';
import ClientBg from './a_media/speakerconsultant_img/client-bg.png';

const Styles = styled.div`
//banner section
.banner-section{
    position: relative;
}

//images
.banner{
    left: 0;
    margin-top:80px;
}

//arrow on the image
.arrow-position{   
    display: flex;
    left:0;
    right:0;
    position:absolute;
    bottom: 40%; 
    justify-content: space-between;  
    align-self:center;
    // width: 1440px; 
}
.arrow-section{
    display: inline;
    margin-left: 3%;
    margin-right: 3%;
    cursor:pointer;  
}

.arrow-section img{
    width: 40%; 
}
.consultant-title{
    margin-top:-5%;
}

//dot on the images
.dot-position{
    display: flex;
    justify-content: center;
    align-item: center;
}

.progressdot{
    position: absolute;
    bottom: 2%;
}

.dot{
    height: 9pt;
    width: 9pt;
    // background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-left:1em;
    margin-right:1em;
}

//title: speaker/ consultant
    .title_swap{
        display:flex;
        justify-content:center;
        text-align: center;
        padding-top: 3%;
        margin-bottom: 5%;
    }
    .title_swap h3{
        display:inline;
        margin-left: 1rem;
        margin-right: 1rem;
        cursor: pointer;
    }  

    .intro{
        padding-left:1rem;
        padding-right:1rem;
    }

    .client-background{
        margin-top:8.5rem;
        padding-top: 6rem;
        padding-bottom: 8rem;
        left:0;
        background-image: url('${ClientBg}'); 
    }
    .previousclents{
        color: #fff;
        margin-bottom: 4%;
        // text-align: right;
    }

    .section{
        margin-bottom: 10rem;
    }


//consultant color line
.speaker-leftline{
    position: absolute;
    left:0;
    top:34em;
    width: 1.5vw;
    // bottom:0;
    height: 2500px;
    background: linear-gradient(180deg, #B39C88 0%, #E01610 50.04%, #000000 99.99%);
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.speaker-rightline{
    position: absolute;
    right:0;
    top:34em;
    width: 1.5vw;
    height: 2500px;
    background: linear-gradient(180deg, #B39C88 0%, #E01610 50.04%, #000000 99.99%);
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.consultant-leftline{
    position: absolute;
    left:0;
    top:0;
    width: 1.5vw;
    height: 1650px;
    background: linear-gradient(180deg, #000000 0%, #E01610 50.2%, #27275B 100.3%);
    transform: matrix(1, 0, 0, 1, 0, 0);
}
.consultant-rightline{
    position: absolute;
    right:0;
    top:0;
    width: 1.5vw;
    height: 1650px;
    background: linear-gradient(180deg, #000000 0%, #E01610 50.2%, #27275B 100.3%);
    transform: matrix(1, 0, 0, 1, 0, 0);
}


//mobile version
@media only screen and (max-width: 620px) {
    .dot{
        height: 8px;
        width: 8px;
        // background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        margin-left:1rem;
        margin-right:1rem;
    }
    .banner{
        margin-top:85px;
    }

    .arrow-position{   
        display: flex;
        left:0;
        right:0;
        position:absolute;
        bottom: 25%; 
        justify-content: space-between;  
        align-self:center;
    }
    .arrow-section{
        display: inline;
        margin-left: 1%;
        margin-right: 1%;  
    }

    .arrow-section img{
        width: 20%; 
    }

    //line mobile
    .speaker-leftline{
        position: absolute;
        left:0;
        top:12em;
        width: 2%;
        // bottom:0;
        height: 3250px;
        background: linear-gradient(180deg, #B39C88 0%, #E01610 50.04%, #000000 99.99%);
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
    
    .speaker-rightline{
        position: absolute;
        right:0;
        top:12em;
        width: 2%;
        height: 3250px;
        background: linear-gradient(180deg, #B39C88 0%, #E01610 50.04%, #000000 99.99%);
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
    .consultant-leftline{
        position: absolute;
        left:0;
        top:0;
        width: 1.5%;
        height: 2000px;
        background: linear-gradient(180deg, #000000 0%, #E01610 50.2%, #27275B 100.3%);
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
    .consultant-rightline{
        position: absolute;
        right:0;
        top:0;
        width: 1.5%;
        height: 2000px;
        background: linear-gradient(180deg, #000000 0%, #E01610 50.2%, #27275B 100.3%);
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
    .consultant-title{
        margin-top:5%;
    }
}
`;

function SpeakerComponent() {
    return (
    <Styles>
        <FontStyle>
            <React.Fragment>
            <div className="speaker-leftline"/>
            <div className="speaker-rightline"/>
            <br/><br/><br/><br/>
            <Container>
                <Row className="top-section">
                    <Col xs={12} md={6}>
                        <iframe 
                            src='https://www.youtube.com/embed/E7wJTI-1dvQ'
                            frameborder='0'
                            allow='autoplay; encrypted-media'
                            title='video'
                            width="100%"
                            height="100%"
                        />   
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="intro">
                        <h4>Introduction</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis magna aenean vestibulum at. Sem in nisl risus at ac turpis convallis purus. Sit fames gravida egestas egestas. Sed id sapien enim id est viverra arcu. Eu vel est facilisi amet orci vitae placerat. Lectus quam eget in cursus eget id proin.
                        <br/><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis magna aenean vestibulum at. Sem in nisl risus at ac turpis convallis purus. Sit fames gravida egestas egestas. Sed id sapien enim id est viverra arcu. Eu vel est facilisi amet orci vitae placerat. Lectus quam eget in cursus eget id proin.
                        <br/><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis magna aenean vestibulum at. Sem in nisl risus at ac turpis convallis purus. Sit fames gravida egestas egestas. Sed id sapien enim id est viverra arcu. Eu vel est facilisi amet orci vitae placerat. Lectus quam eget in cursus eget id proin.</p>
                        </div>
                    </Col>
                </Row>
                <br/><br/> <br/><br/>
                {Speakerinfo.map((ele) => {
                    return (
                        <React.Fragment>
                            <br/>
                            <ImgLeft
                                title={ele.title}
                                imgsrc={ele.imgsrc}
                                description={ele.description}
                            />
                        </React.Fragment>
                )})}
                    <div className="section"/>
                    </Container>
            </React.Fragment>
        </FontStyle>
    </Styles>
    );
}


function ConsultantComponent() {
    return (
     <Styles>
        <FontStyle>
            <React.Fragment>
            <div className="consultant-leftline"/>
            <div className="consultant-rightline"/>
                <Container>
                   
                    {/* <div className="rightline">
                        <img src={LineRight} />
                    </div> */}

                    <Row className="consultant-title">
                        <Col xs={12} md={5}>
                            <h4 style={{marginLeft: '13%'}}> <strong>Areas of Specialization</strong></h4>
                        </Col>
                    
                    </Row>
            
                    <br/>
                    {Consultantinfo.map((ele) => {
                        return (
                            <React.Fragment>
                                <Row>
                                    <Col xs={12} md={{offset: 2, span:2}}>
                                        <h4>{ele.title}</h4>
                                    </Col>
                                    <Col xs={12} md={{offset: 1, span:6}}>
                                        <p>{ele.description}</p>
                                    </Col>
                                </Row>
                            </React.Fragment>
                    )})}
                </Container>
                <div className="client-background">
                    <Container>
                        <Row>
                            <Col className="previousclents" xs={12} md={{offset: 1, span: 3}}>
                                <h4>Previous Clients</h4>
                            </Col>
                            <Col xs={12} md={{offset: 1, span: 7}}>
                                <img src={Previousclient} width="80%"/>
                            </Col>
                        </Row>
                    </Container>
                </div>
             
            </React.Fragment>
        </FontStyle>
    </Styles>
    );
}


const Speaker_Consultant=()=>{
    const [content, setContent] = useState(<SpeakerComponent /> );
    // const [arrowsection, setArrowsection] = useState(<ArrowRight /> );
    const [imgswap, setImgswap] = useState(Speakerbanner);
    const [speakercolor, setSpeakercolor] = useState('#000000');
    const [consultantcolor, setConsultantcolor] = useState('#000000');
    const [dot1color, setDot1color] = useState('#000');
    const [dot2color, setDot2color] = useState('#000');
    const [arrow1opacity, setArrow1opacity] = useState('0%');
    const [arrow2opacity, setArrow2opacity] = useState('0%');
    // const [speakerbar, setSpeakerbar] = useState('100%');
    
    useState(()=>{ 
        setConsultantcolor('#c4c4c4');
        setContent(<SpeakerComponent/> );
        setDot2color('#fff');
        setArrow1opacity('100%');
        // setSpeakerbar('100%');
    },[])

    const HandleClick = (e) =>{
        e.preventDefault(); 
        const clickID = e.target.id;
        console.log("clicked id!",clickID);
        if (clickID == '1'){
            setArrow1opacity('100%');
            setArrow2opacity('5%');

            setImgswap(Speakerbanner);
            setContent(<SpeakerComponent /> );

            setSpeakercolor('#000000');
            setConsultantcolor('#c4c4c4');
          
            setDot1color('#000');
            setDot2color('#fff');
           
            // setSpeakerbar('0%');
            // setArrowsection(<ArrowRight/>);
        }else{
            setArrow1opacity('5%');
            setArrow2opacity('100%');

            setImgswap(Consultantbanner);
            setContent(<ConsultantComponent /> );
            
            setSpeakercolor('#c4c4c4');
            setConsultantcolor('#000000');
           
            setDot1color('#fff');
            setDot2color('#000'); 
  
            // setSpeakerbar('100%');
            // setArrowsection(<ArrowLeft/>);
        }        
    }
    
    return (
        <Styles>
            <FontStyle>
                <React.Fragment>
               
                    <div className="banner-section">
  
                        <img className="banner" src={imgswap} width="100%"/>
                        

                        <div className="arrow-position">
                            <div className="arrow-section">
                                <img id ="1" style={{opacity:arrow2opacity}} onClick={HandleClick} src={ArrowLeft}/>
                            </div>
                            <div className="arrow-section">
                                <img id ="2" style={{opacity:arrow1opacity}} onClick={HandleClick} src={ArrowRight}/>
                            </div>
                        </div>

                    
                        <div className="dot-position">
                            <div className="progressdot">
                                <div id ="1" style={{backgroundColor:dot1color}} onClick={HandleClick} className="dot"></div>
                                <div id ="2" style={{backgroundColor:dot2color}} onClick={HandleClick} className="dot"></div>
                            </div>
                        </div>
                        
                       
                    </div>
                  
                    {/* <div className="speaker-leftline speaker-rightline"> */}
                    {/* <div className=""> */}
                    
                        
                        <div className="title_swap">
                            <h3 id ="1" style={{color:speakercolor}} onClick={HandleClick}>Speaker</h3>
                            <h3 id ="2" style={{color:consultantcolor}} onClick={HandleClick}>Consultant</h3>
                        </div>
                    {/* <div className="speaker-rightline"/>
                    <div className="speaker-leftline"/> */}
                        {content}
                        {/* </div> */}
                    {/* </div> */}
                </React.Fragment>
            </FontStyle>
        </Styles>
    )

}

export default Speaker_Consultant;