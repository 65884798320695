/*For individual research page in the Research*/
import React from 'react';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BreadCrumb from '../../components/Breadcrumb';
import CardPublication from '../CardElement/CardPublication';
import ResearchMap from '../../content/Research/research_map.json';
import CategoryPub from '../../content/Publication/category_pub_map.json';
import PubContent from '../../content/Publication/pub_content_map.json';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const Styles = styled.div`
    img{
        margin-top: 2rem;
        margin-bottom: 2rem;
        // width:85%;
    }
    a{
        cursor: pointer;
    }
    h3{
        margin-top: 5rem;
    }
    .card{
        margin:0;
    }
    .card-img-top {
        margin:0;
    }

    .moresection{
        color: #B30009;
        margin-top: 2rem;
        margin-left: 2rem;
        margin-bottom: 2rem;
       
    }
    .section{
        margin-bottom: 10rem;
    }

    .coverimg{
        width:100vw;
        max-height:400px;
        object-fit: cover;
    }
    .dropdown-toggle{
        border: 0.5px solid #DCDCDC;
        background-color: #fff !important;
        color: #000 !important;
    }
    a:active{
        background-color: #B30009;
    }
    //mobile
    @media only screen and (max-width: 450px) {
        .moresection{
            color: #B30009;
            margin-top: 1rem;
            margin-left: 0rem;
            margin-bottom: 2rem;
        }
    }

`;

/*breadcrumbbbbbb*/
export default function ResearchPage() {
    let current_pathname = window.location.pathname.split("/");
    const researchtopics = decodeURIComponent(current_pathname[2])
    
    const [renderlength, setrenderlength] = useState(2);
    const [readstatus, setReadstatus] = useState("More...");
    const pubLength = CategoryPub[researchtopics].length;

    function getBold(currentID, dropdownID) {
        if (currentID === dropdownID) {
            return { 'font-weight': '700' }
        }
        return {}
    }

    useEffect(() => {
        if (pubLength <= 2){
            setReadstatus("");
        }else{
            setReadstatus(((pubLength-2).toString()) + " More...");
        }
    },[]);

    const HandleClick = (e) => {
        e.preventDefault();
        if (readstatus === "...Less"){
            setrenderlength(2);
            setReadstatus(((pubLength-2).toString()) + " More...");
        }else{
            setrenderlength(pubLength);
            setReadstatus("...Less");
        }
    }
  return(
    <Styles>
        <FontStyle>
            <React.Fragment>
                {Object.keys(ResearchMap).filter((key) => key === researchtopics).map((topic, index) => {
                    return (
                        <div topic={index}>
                            <img className="coverimg" src={ResearchMap[topic].imgsrc}/> 

                            <Container>
                                <Row>
                                    <BreadCrumb
                                        link1={'/'} link2={'/research'} secondlevel={'Research'}
                                        current={topic}
                                    />
                                </Row>
                                <Row>
                                    <Col xs={12} lg={9}>
                                        <h2>{topic}</h2>
                                    </Col>
                                    <Col className="mt-auto mb-auto" xs={12} lg={3}>
                                        <DropdownButton title={"Select a research topic"}>
                                            <Dropdown.Item href={"/research"}>
                                                All
                                            </Dropdown.Item>
                                            {Object.keys(ResearchMap).map((ele, index) => {
                                                return (
                                                    <div ele={index}>
                                                        <Dropdown.Item href={"/research/" + encodeURIComponent(ele)} style={getBold(decodeURIComponent(current_pathname[2]), decodeURIComponent(ele))}>
                                                            {ele}
                                                        </Dropdown.Item>
                                                    </div>
                                                );
                                            })
                                            }
                                        </DropdownButton>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={6}>
                                        <img src={ResearchMap[topic].thumbnailimg} width='100%' />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <br />
                                        <p>{ResearchMap[topic].short_description}</p>
                                        <p>{ResearchMap[topic].long_description}</p>
                                    </Col>
                                </Row>
                                <h3>Publications</h3>
                                {CategoryPub[topic].slice(0, renderlength).map((pub, index) => {
                                    return (
                                        <div pub={index}>
                                            <CardPublication
                                                title={pub}
                                                pdflink={PubContent[pub]["pdf link"]}
                                                abstract_title={PubContent[pub].title}
                                                abstract_body={PubContent[pub].abstract}
                                            />
                                        </div>
                                    )
                                })}

                                <div className="moresection" onClick={HandleClick}>
                                    <a>{readstatus}</a>
                                </div>

                                <h3>Projects</h3>
                                <Row>
                                    <Col xs={12} md={5}>
                                        <img src={ResearchMap[topic].project1_img} width="100%" />
                                        <p style={{ fontWeight: '600' }}>{ResearchMap[topic].project1_title}</p>
                                        <p>{ResearchMap[topic].project1_description}</p>
                                    </Col>
                                    <Col xs={12} md={{ offset: 2, span: 5 }}>
                                        <img src={ResearchMap[topic].project2_img} width="100%" />
                                        <p style={{ fontWeight: '600' }}>{ResearchMap[topic].project2_title}</p>
                                        <p>{ResearchMap[topic].project2_description}</p>
                                    </Col>
                                </Row>
                            </Container> 
                        </div>
                    )
                }
                )}
                <Container>

                <div className="section"/>

                </Container>
            </React.Fragment>
        </FontStyle>
    </Styles>
  );
}


// export default withRouter(MemberPage);