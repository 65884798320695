/*the background image & headshot of home page */
import React,{useState, useEffect} from 'react';

import { Jumbotron as Jumbo, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Home_bg from '../../a_media/images/Home_bg.png';
import Home_headshot from '../../a_media/images/Home_headshot.png';
import Mobile_home_bg from '../../a_media/images/Mobile_home_bg.png';
import Mobile_home_headshot from '../../a_media/images/Mobile_home_headshot.png';
import Travelschedule_hide from '../../a_media/images/Home_travelschedule_hide.png';
import Travelschedule_show from '../../a_media/images/Home_travelschedule_show.png';

const GlobalStyles = styled.div`
.helveticaArrow{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline;
}
.selfintro{
  color: #fff;
}

.selfintro p{
  font-size: 12pt;
  font-weight: 400!important;
}
`;


const DesktopStyles = styled.div`
//desktop style
.home-banner{
  position:relative;
}

.jumbo {
  position: absolute;
  top: 0;
  z-index:-1;
  background-color: #000;
  padding-bottom: 75vh;
}

.overlay {
  position: absolute;
  top: 45%;
  left: 10%;
  bottom: 0;
  right: 0;
}


.nametag{
  padding-top: 33vh;
  padding-bottom: 11rem;
  color: #fff;
}
.nametag h1{
  font-size: 40pt !important;
  color: #fff;
  font-weight: 500;
}
.nametag h4{
  font-size: 16pt!important;
  color: #fff;
  font-weight: 500;
}

.selfintro{
  margin-left: 4rem;
}
.container-selfintro{
  left:0;
  right:0;
  padding-bottom: 3rem;
  background-color:#000;
}
.travelschedule{
  cursor:pointer;
  margin-left:-5%;
}
`;

 function DesktopComponent() {
  const [showResults, setShowResults] = useState(false); 
  return (
    <React.Fragment>
      <GlobalStyles>
      <DesktopStyles>
        <br/><br/><br/>
        <div className="home-banner">
          <div className="jumbo">
            <img src={Home_bg} width='100%'/>
          </div>
          <div className="overlay">
              <img src={Home_headshot} width='16%'/>
          </div>

          <div className="nametag text-center">
            <h1>David Kirsh</h1>
            <h4>PROFESSOR  &nbsp;&nbsp; • &nbsp;&nbsp;  RESEARCHER  &nbsp;&nbsp; • &nbsp;&nbsp;  CONSULTANT</h4>
          </div>
        </div>

        <Row className = "container-selfintro">
          <Col sm={8} lg={{offset: 3, span:6}}>
            {/* <Slider/> */}
            {/* <div className = "center-container"> */}
              <div className="selfintro">
                <h3>Who Am I</h3>
                <p>
                <div className="helveticaArrow">→</div> <i>Professor</i>, Cognitive Science, UCSD [past dept. chair] <br/>
                <div className="helveticaArrow">→</div> <i>President</i>, Academy of Neuroscience for Architecture<br/>
                <div className="helveticaArrow">→</div> <i>Visiting Professor</i>, Bartlett School of Architecture, University College London</p>
                <h3>My Research Interests</h3>
                <p>
                <div className="helveticaArrow">→</div> Embodied and Extended Cognition<br/>
                <div className="helveticaArrow">→</div> Design Thinking and Practice<br/>
                <div className="helveticaArrow">→</div> Visual Thinking<br/>
                <div className="helveticaArrow">→</div> Creativity </p>
                <h3>Education</h3>
                <p>
                <div className="helveticaArrow">→</div> Post Doctoral — <strong>MIT AI Lab</strong><br/>
                <div className="helveticaArrow">→</div> D.Phil / Ph.D — <strong>Oxford University</strong><br/>
                <div className="helveticaArrow">→</div> BA + MA — <strong>University of Toronto</strong><br/> </p>
              </div>
            {/* </div> */}
            </Col>
            <Col className="travelschedule" sm={4} lg={{span:3}}>
              <div onClick = {() => setShowResults(!showResults)} >
                {showResults === true
                  ? <img src={Travelschedule_show} width='90%'/>
                  : <img src={Travelschedule_hide} width='90%'/>}
              </div>
            </Col>
            </Row>
        
      </DesktopStyles>
      </GlobalStyles>
    </React.Fragment>
  )
}

const MobileStyles = styled.div`
//mobile style
.home-banner{
  position: relative;
}

.mobile_jumbo{
  position: absolute;
  top: 0;
  z-index:-1;
  width:100vw;
  background-color: #000;
  padding-bottom: 70vh;
}

.mobile_overlay{
  position: absolute;
  z-index:-1;
  top: 12vh;
  left: 12pt;
  bottom: 0;
  right: 0;
}

.mobile_nametag{
  padding-top: 35vh;
  margin-bottom: 3rem;
}
.mobile_nametag h1{
  font-size: 26pt;
  color: #fff;
}
.mobile_nametag h4{
  font-size: 12pt;
  color: #fff;
  margin-top:-0.5rem;
}

.mobile_travelschedule{
  color: #000;
}

.container-selfintro{
  padding-top:2rem;
  padding-bottom: 3rem;
  background-color:#000;
}

.row{
  padding-right:0px;
  padding-left:0px;
}
.container{
  padding-left: 20px;
  padding-right: 0;
}

`;

function MobileComponent() {
  const [showResults, setShowResults] = useState(false); 
  return (
    <React.Fragment>
      <GlobalStyles>
      <MobileStyles>
        <div className="home-banner">
          <div className="mobile_jumbo">
              <img src={Mobile_home_bg} width="100%"/>
          </div>
          <div className="mobile_overlay">
              <img src={Mobile_home_headshot}  width="33%"/>
          </div>
          <div className="mobile_nametag text-center">
            <h1>David Kirsh</h1>
            <h4>PROFESSOR  •  RESEARCHER  •  CONSULTANT</h4>
          </div>
        </div>
          
          {/* <Slider/> */}
          {/* <Container> */}
          <Row className ="container-selfintro">
            <Col sm={6} xs={12}>
              <Container className="selfintro">
                <h3>Who Am I</h3>
                <p>
                <div className="helveticaArrow">→</div> Professor, Cognitive Science, UCSD <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [past dept. chair] <br/>
                <div className="helveticaArrow">→</div> President, Academy of Neuroscience <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; for Architecture<br/>
                <div className="helveticaArrow">→</div> Visiting Professor, Bartlett School of <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Architecture, University College London</p>
                <h3>My Research Interests</h3>
                <p>
                <div className="helveticaArrow">→</div> Embodied and Extended Cognition<br/>
                <div className="helveticaArrow">→</div> Design Thinking and Practice<br/>
                <div className="helveticaArrow">→</div> Visual Thinking<br/>
                <div className="helveticaArrow">→</div> Creativity </p>
                <h3>Education</h3>
                <p>
                <div className="helveticaArrow">→</div> Post Doctoral — <strong>MIT AI Lab</strong><br/>
                <div className="helveticaArrow">→</div> D.Phil / Ph.D — <strong>Oxford University</strong><br/>
                <div className="helveticaArrow">→</div> BA + MA — <strong>University of Toronto</strong><br/> </p>
              </Container>
            </Col>
            <br/>
            <Col className="travelschedule" sm={6} xs={12}>
              <Container>
              <div onClick = {() => setShowResults(!showResults)} >
                {showResults === true
                  ? <img src={Travelschedule_show} width='90%'/>
                  : <img src={Travelschedule_hide} width='90%'/>}
              </div>
              </Container>
            </Col>
          </Row>
          {/* </Container> */}

      </MobileStyles>
      </GlobalStyles>
    </React.Fragment>
  )
}

const Header= () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
   
  return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
}
    

export default Header;