import React from 'react';
import AboutWithBubbles from './components/DisplayPage/AboutWbubbles';
import AboutWithoutBubbles from './components/DisplayPage/AboutNbubbles';
import { AboutRegular } from './components/DisplayPage/AboutRegular';

const RandGen = (maxnum) =>(
    Math.floor(Math.random() * maxnum)
)

const About = () => {
    const maxnum = 9;
    let randomnum = parseInt(window.sessionStorage.getItem("randomnum"))
    if (isNaN(randomnum)){
        // random number not set, set the random number
        randomnum = RandGen(maxnum);
        window.sessionStorage.setItem("randomnum", randomnum);
    }

    if (randomnum <= 4){
        return <AboutWithBubbles/>
    }else if (4 < randomnum && randomnum <= 6){
        return <AboutRegular/>
    }else if (6 < randomnum && randomnum <= 8){
        return <AboutWithoutBubbles/>
    }
    
}

export default About;