/*
  Publication landing page: 
  - Use CardPublication to format how each publication looks
  - Render Publications data using CategoryPub & PubContent
  */
import React, { useState } from 'react';

import styled from 'styled-components';
import FontStyle from './FontStyles';
import { Container } from 'react-bootstrap';

import PubDropdown from './components/PubDropdown';

import CardPublication from './components/CardElement/CardPublication';
import Button from 'react-bootstrap/Button';

import CategoryPub from './content/Publication/category_pub_map.json';
import PubContent from './content/Publication/pub_content_map.json';
import { ThemeContext } from './App';

const Styles = styled.div`
    .researchtop{
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap;
    }
    .dropdown-toggle{
        margin-top: 35%;
    }

    .showall{
       background-color:#B30009 !important;
       color:#fff;
       border:none;
    }
    .section{
        margin-bottom: 5rem;
    }

`;

export const Publications = () => {
    const [currentTopic, setcurrentTopic] = useState(""); 

    return(
    <Styles>
        <FontStyle>
            <ThemeContext.Provider value={{ currentTopic, setcurrentTopic}}>
                <React.Fragment>
                    <Container>
                    <br/><br/><br/><br/>

                    {/* dropdown */}
                    <div className="researchtop">
                        <h2>Publications</h2>                        
                        <PubDropdown
                            title={'Filter by topic: '}
                            dropdownItemList={Object.keys(CategoryPub)}
                        />
                    </div>
                    
                    {Object.keys(CategoryPub).filter((key) => currentTopic === "" || key === currentTopic).map((key, index) => {
                        return (
                            <div key={index}>
                                <h4 class="mt-4 mb-3">{key}</h4>
                                {CategoryPub[key].map((pub,index) => {
                                    return (
                                        <div pub={index}>
                                            <CardPublication
                                                title={pub}
                                                pdflink={PubContent[pub]["pdf link"]}
                                                abstract_title={PubContent[pub].title}
                                                abstract_body={PubContent[pub].abstract}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    )}

                    {currentTopic !== "" && <Button className="showall" onClick={() => setcurrentTopic("")}> Show All Topics</Button>  }

                    <div className="section"/>

                    </Container>
                </React.Fragment>
            </ThemeContext.Provider>
        </FontStyle>
    </Styles>
    );
}