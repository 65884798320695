/*research info */
import Creativity from '../a_media/research_img/Creativity.png';
import Dance from '../a_media/research_img/Dance.png';
import Dancecover from '../a_media/research_img/Dance-cover.png';

import Architecture from '../a_media/research_img/Architecture.png';

const Researchinfo = [
    {
        id: 1,
        title:'Choreography',
        thumbnailimg: Dance,
        imgsrc: Dancecover,
        shortdescription: 'Coversed, distributed, enactive, and embodied cognition. Explains how cyborgs are a natural consequence of our current understanding of embodied minds embedded in culturally shaped niches; how mental systems can be distributed over other people and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "", 
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Roles of Imagery",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"How Randomness Enhances Creativity",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
    },
    {
        id: 2,
        title: 'Architecture',
        thumbnailimg: Architecture,
        imgsrc: Architecture,
        shortdescription: 'Coveeer people and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "",
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 architecture",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 architecture",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
    },
    {
        id: 3,
        title: "Implicit/Explicit",
        thumbnailimg: Architecture,
        imgsrc: 'https://picsum.photos/id/34/1200/600',
        shortdescription: 'Coveofuted, enactive, and embodied cognition. Explains how cyborgs are a natural consequence of our current understanding of embodied minds embedded in culturally shaped niches; how mental systems can be distributed over other people and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "",  
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 ai",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 ai",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
    },
    {
        id: 4,
        title:'Creativity',
        thumbnailimg: Architecture,
        imgsrc: Creativity,
        shortdescription: 'Covers the thengs.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "",
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 creativity",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 creativity",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
    },
    {
        id: 5,
        title: "Perceptual thinking",
        thumbnailimg: Architecture,
        imgsrc: 'https://picsum.photos/id/82/1200/600',
        shortdescription: 'Coverated, distributed, enactive, and embodied cognition. Explains how cyborgs are a natural consequence of our current understanding of embodied minds embedded in culturally shaped niches; how mental systems can be distributed over other people and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "",
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 thinking in senses",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 thinking in senses",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
    },
    {
        id: 6,
        title: "Representation",
        thumbnailimg: Architecture,
        imgsrc: 'https://picsum.photos/id/19/1200/600',
        shortdescription: 'Coved, distributed, enactive, and embodied cognition. Explains how cyborgs are a natural consequence of our current understanding of embodied minds embedded in culturally shaped niches; how mental systems can be distributed over other people and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "",   
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 representation",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 representation",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",  
    },
    {
        id: 7,
        title: 'Interactivity',
        thumbnailimg: Architecture,
        imgsrc: 'https://picsum.photos/id/29/1200/600',
        shortdescription: 'Coversated, distributed, enactive, and embodied cognition. Explains how cyborgs are a natural consequence of our current understanding of embodied minds embedded in culturally shaped niches; how mental systems can be distributed over other people and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "",   
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 interactivity",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 interactivity",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",  
    },
    {
        id: 8,
        title: 'Embodied, Extended, Situated',
        thumbnailimg: Architecture,
        imgsrc: 'https://picsum.photos/id/69/1200/600',
        shortdescription: 'Covers and things.',
        long_description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo',
        // link: "", 
        project1_img:"https://picsum.photos/id/34/1200/600",
        project1_title:"Project 1 work environment",
        project1_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
        project2_img:"https://picsum.photos/id/32/1200/600",
        project2_title:"Project 2 work environment",
        project2_description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore redondo bongo",
    },
]
  
  export default Researchinfo;
  
  