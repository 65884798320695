/*For research section used on Home page*/
import React,{useState} from 'react';
import {Link} from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'

import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import Researchinfo from '../../content/Researchinfo.js';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ResearchMap from '../../content/Research/research_map.json';

import Carousel from 'react-responsive-carousel';
import * as Icon from 'react-feather';

// import Home_headshot from '../../a_media/images/Home_headshot.png';
// import Mobile_home_bg from '../../a_media/images/Mobile_home_bg.png';
// import Mobile_home_headshot from '../../a_media/images/Mobile_home_headshot.png';
// import Travelschedule_hide from '../../a_media/images/Home_travelschedule_hide.png';
// import Travelschedule_show from '../../a_media/images/Home_travelschedule_show.png';


const Styles = styled.div`
h3{
    color: #fff;
}
.bg-bl {
    // background-size: cover;
    // position: absolute;
    background-color: #000;
    left: 0;
    width: 100vw;
    height: 90vh;
    padding-top: 3rem;
}

.researchsection{
    display: flex;
    margin-top: 5%;
}

.topicselection{
    margin-top: 12%;
}

.btn{
    font-family: Lato;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    margin-bottom: 3%;
    font-size: 14px; 
    background: rgba(227, 52, 21, 0.4);
    border-radius: 5px;
    border: none;
}

.jumbo{
    background-color: #fff;
}

//mobile carousel
    .mobile_jumbo{
        // position: absolute;
        background-color: #000;
        left:0;
        width: 100vw;
        padding-top: 8%;
        padding-bottom: 8%;
    }
    .research_mobile{
        margin-left: 24px;
        margin-right: 24px;
    }

    .alice-carousel__dots-item:not(.__custom).__active {
        background-color: #white;
    }



`;

//change button status
function getColor(currentID,hoverID){
    if (currentID == hoverID){
        return {'background':'rgba(227, 52, 21, 1)'}
    }
    return {}
}

 function DesktopComponent() {
     const history = useHistory();
    //img change
     const [topicImg, settopicImg] = useState(ResearchMap[Object.keys(ResearchMap)[0]].thumbnailimg);
    //button status change
    const [btnID, setbtnID] = useState(Object.keys(ResearchMap)[0]);
     
    const Mouseenter = (e) => {
        e.preventDefault();
        const currentTopic = e.target.value; //get hovered value
        settopicImg(ResearchMap[currentTopic].thumbnailimg);
        setbtnID(currentTopic);
    }
    
  return (
    <React.Fragment>
     <Styles>
        <div className="bg-bl">
            <Container>
            <Row className="researchsection">    
                <Col xs={12} md={8}>
                    <img src={topicImg} width="95%"/>
                </Col>
                <Col xs={12} md={4}>
                    <h3>Research Topics</h3>
                    <div className="topicselection">
                        {Object.keys(ResearchMap).map((topic, index) => {
                            return (
                                <div topic={index}>
                                    <Button onMouseEnter={Mouseenter} onClick={() => history.push(`/research/` + encodeURIComponent(topic))} value={topic} style={getColor(btnID, topic)} >{topic.toUpperCase()}</Button>
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
      </Styles>
    </React.Fragment>
  )
}

function MobileComponent() {
    const history = useHistory();
    function getColor(currentID,selectedID){
        if (currentID === selectedID){
            return {'background':'rgba(227, 52, 21, 1)'}
        }
        return {}
    }
    const handleDragStart = (e) => e.preventDefault();
    const [activeIndex, setActiveIndex] = useState(0);
    const onSlideChanged = ({ item }) => setActiveIndex(item);
    const imageArray = Object.keys(ResearchMap).map((topic) => <img onClick={() => history.push(`/research/` + encodeURIComponent(topic))} src={ResearchMap[topic].thumbnailimg} width="100%" onDragStart={handleDragStart} />);

    return (
    <React.Fragment>
        <Styles>
            <div className="mobile_jumbo">
                <div className="research_mobile">
                    <h3>Research Topics</h3>
                    <AliceCarousel activeIndex={activeIndex} onSlideChanged={onSlideChanged} items={imageArray} infinite={true}/>
                    <div className="topicselection">
                        {Object.keys(ResearchMap).map((topic, index) => {
                            return (
                                <div topic={index}>
                                    <Button style={getColor(activeIndex, index)} onClick={() => history.push(`/research/` + encodeURIComponent(topic))}>{topic.toUpperCase()}</Button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>  
        </Styles>
    </React.Fragment>
  )
}

const CardResearchHome = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
   
  return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
}
    

export default CardResearchHome;