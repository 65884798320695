/*the background image & headshot of home page */
import React,{useState, useEffect} from 'react';
import { Jumbotron as Jumbo, Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import styled from 'styled-components';
import {AboutmePersonally} from '../../../content/Aboutmeinfo';
import FontStyle from '../../../FontStyles';
import BreadCrumb from '../../../components/Breadcrumb';

//import image
import Greenbar from "../../../a_media/about_img/bubblesdesign/greenbar.png";


const Style = styled.div`
h3{
  margin-top: 2rem;
  color: #fff;
}
p{
  color: #fff;
  font-weight: 400;
  line-height: 22px;
}
.row{
    margin-left:0;
    margin-right:0;
}
img{
    width:90%;
}

i{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color:#fff;
}


//button css
.btn-style{
  position: relative;
}
.btn-primary{
  position: absolute;
  border-radius: 0px;
  background-color: #B30009;
  border: none;
  width: 100px;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  z-index:1;
}

.borderline{
  border: 2px solid #FFFFFF;
  width: 100px;
  height: 40px;
  z-index:0;
  position: absolute;
  margin-top: 3%;
  margin-left: 3%;
}

.pre-btn{
    position: relative;
}
.pre-btn .borderline{
    margin-top: 2%;
    margin-left: 2%;
}
.next-btn {
    position: relative;
    left:65%;
}


//breadcrumb style
.breadcrumb{
    background-color: #000000 !important;
}
.breadcrumb-item a{
    color: #FD3A44;
}
.breadcrumb-item.active{
    color: #fff !important;
}


//spacing
.spacing{
    margin-top:5rem;
}
.btn-spacing{
    margin-top:15rem;
    margin-bottom:2rem;
}


//btn text
.navi-text i{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#c4c4c4;
}
.navi-text{
    margin-top:4rem;
}
.sidecolor{
    position: fixed;
    width: 1.3%;
    bottom:0px;
    left: 0px;
    top: 100px;
    background: linear-gradient(180deg, #000000 0%, #F99106 14.06%, #B82B49 81.25%, #000000 100%);
    transform: rotate(0deg);
}
.greenbar{
    position: absolute;
    width: 6%;
    right: -100px;
    top: 30%;
}

@media only screen and (max-width: 800px) {
    .img-section{
        padding-bottom:1rem;
    }
    img{
        width:100%;
    }
    .spacing{
        margin-top:3rem;
    }
    .btn-spacing{
        margin-top:5rem;
        margin-bottom:2rem;
    }
    .container{
        padding-left:0;
        padding-right:0;
    }
    .next-btn {
        position: relative;
        left:28%;
    }
    .greenbar{
        position: absolute;
        width: 6%;
        right: 3px;
        top: -20%;
    }
}

`;



export default function AboutMePersonally(props) {
    return (
        <React.Fragment>
          
          <Container fluid style={{backgroundColor: "#000000", paddingBottom:"20vh", top: "0",width:"100vw", zIndex:"1"}}>
            <Style>
              <FontStyle>
                <Container>
                    <br/><br/> <br/> <br/>
                    <div className="sidecolor"></div>
                   
                    <Row>
                        <BreadCrumb 
                            link1={'/'} link2 ={'/about'} secondlevel={'About Me'}
                            current={AboutmePersonally[0].title}
                        />
                    </Row>
                    <Row>
                        <Col xs={12} md={{offset:1,span:4}}>

                            <img src={AboutmePersonally[0].imgsrc_bubbles} width="100%"/>
                        </Col>
                        <Col xs={12} md={{offset:1, span:4}}>
                            <h3>{AboutmePersonally[0].title}</h3>
                            <p>{AboutmePersonally[0].description}</p>
                            <img className="greenbar" src={Greenbar} width="100%"/>
                        </Col>
                    </Row>
                <br/> <br/> 
               
                {AboutmePersonally.slice(1, AboutmePersonally.length+1).map((ele) => { 
                   return (
                        <React.Fragment>
                            <Row className="spacing">
                                <Col xs={12} md={{offset:1,span:4}}>
                                    <p>{ele.description}</p>
                                </Col>
                                <Col xs={12} md={{offset:1,span:5}} className="order-first order-md-last">
                                    <div className="img-section">
                                        <img src={ele.imgsrc_bubbles}/>
                                        <br/>
                                        <i>{ele.imgsrc_caption}</i>
                                        <br/>
                                    </div>
                                </Col>
                            </Row> 
                        </React.Fragment>
                    );
                })}

                


                <Row className="btn-spacing">
                    {/* <div className="btn-section"> */}
                        <Col xs={6} md={6}>
                            {/* <div className=""> */}
                                <div className="pre-btn btn-style">
                                    <Button href={'/about'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="40" y1="12" x2="5" y2="12"></line>
                                            <polyline points="12 19 5 12 12 5"></polyline>
                                        </svg> &nbsp;&nbsp;&nbsp;
                                        Back
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div> */}
                                <div className="navi-text">
                                    <i>to About Me</i>
                                </div>
                            
                        </Col>
                        <Col xs={6} md={{offset:2, span:4}}>
                            {/* <div > */}
                            {/* <div className=""> */}
                                <div className="next-btn btn-style">
                                    <Button href={'/about/artistentrance'}>
                                        Next &nbsp;&nbsp;&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="0" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                    </Button>
                                    <div className="borderline"/>
                                </div>
                            {/* </div>     */}
                                <div style={{textAlign:'right'}} className="navi-text">
                                    <i>to Through the Artist Entrance</i>
                                </div>
                            {/* </div> */}
                      
                        </Col>
                        {/* </div> */}
                    </Row> 

                </Container>

               
              </FontStyle> 
            </Style>
          </Container>
        </React.Fragment>
    );
}