/*
  Research landing page: 
  - Use CardResearch to format how each research card looks
  - Render Researchinfo for research
  
  If want to change research info, navigate to content --> Courseinfo.js
*/

import React from 'react';
import styled from 'styled-components';

import { Container, Row, Col } from 'react-bootstrap';
import CardResearch from './components/CardElement/CardResearch';
import FontStyle from './FontStyles';
import ResearchCover from './a_media/research_img/Dance-cover.png';
import ResearchMap from './content/Research/research_map.json';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const Styles = styled.div`
  a{
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
  .researchcard{
    margin-top: 3rem;
  }
  .section{
    margin-bottom: 10rem;
  }
  .coverimg{
    width:100vw;
    padding-top:5rem !important;
    object-fit: cover;
  }
  .dropdown-toggle{
      border: 0.5px solid #DCDCDC;
      background-color: #fff !important;
      color: #000 !important;
  }
  a:active{
      background-color: #B30009;
  }
`;

export const Research = () => {
    return(
      <Styles>
      <FontStyle>
        <React.Fragment>
            <img className="coverimg" src={ResearchCover} />
          <Container>
            <Row>
              <Col xs={12} lg={9}>
                <h2>Research</h2>
              </Col>
              <Col className="mt-auto mb-auto" xs={12} lg={3}>
                <DropdownButton title={"Select a research topic"}>
                  <Dropdown.Item style={{fontWeight: 700}}>All</Dropdown.Item>
                  {Object.keys(ResearchMap).map((ele, index) => {
                    return (
                      <div ele={index}>
                        <Dropdown.Item href={"/research/" + encodeURIComponent(ele)}>
                          {ele}
                        </Dropdown.Item>
                      </div>
                    );
                  })
                  }
                </DropdownButton>
              </Col>
            </Row>
    
          <Row className="section">
            {Object.keys(ResearchMap).map((ele, index) => {
              return (
                <Col xs={12} md={6}>
                  <div ele={index} className="researchcard">
                    <CardResearch
                      thumbnailimg={ResearchMap[ele].thumbnailimg} title={ele}
                      shortdescription={ResearchMap[ele].short_description} 
                      link={"/research/" + encodeURIComponent(ele)}
                    />
                  </div>
                </Col>
                );
              })
            }
          </Row> 

        </Container>    
    </React.Fragment>
  </FontStyle>
  </Styles>
);}