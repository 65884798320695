import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = (
  {text}) => <p style={{fontSize: '11pt', fontWeight: '700', lineHeight:'15pt',color:'#B30009'}}>{text}</p>;


class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 32.8808932735427,
      lng: -117.24004440896073
    },
    zoom: 15
  };


  renderMarkers(map, maps) {
    let marker = new maps.Marker({
      position: {
        lat: 32.8808932735427,
        lng: -117.24004440896073
      },
      map,
      title: 'Social Science Research Building 100 (SSRB 100)'
    });
  }

  

  render() {
    return (
        
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:'AIzaSyA8-PYN49fziGxox4S2jrzqOEZOB51CmDY' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
        >
        {/* <Marker lat={props.lat} lng={props.lng} /> */}
          <AnyReactComponent
            lat={32.8808932735427}
            lng={-117.24004440896073}
            text="Social Science Research Building 100"
          />
        </GoogleMapReact>
      </div>
    );
  }
}


export default SimpleMap;