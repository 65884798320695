/*member info */

import MayyaA from "../a_media/lab_img/Member_MayyaA.png";


export const Gradinfo = [
    {
        id: 1,
        name: 'Mayya (Maya) Azarova',
        imgsrc: MayyaA,
        major: 'Anthropology',
        year:'Ph.D. Candidate',
        contact_email:'mazarova@ucsd.edu',
        contact_site:'',
        short_bio:'I’m a PhD student in Cognitive Science at UC San Diego, working with Judy Fan and David Kirsh. I study the cognitive tools that underlie our ability to create new things. As well as shed light on how we convert ideas into physical stuff, I hope that my research can be used to engineer more organic relationships between humans and creative machines.’',
    },
    {
        id: 2,
        name: 'Michael Allen',
        imgsrc: 'https://picsum.photos/id/35/600/600',
        major: 'Cognitive Science',
        year:'PhD',
        contact_email:'wmmcarthy@ucsd.edu',
        contact_site:'https://wpmccarthy.github.io/',
        short_bio:'I’m a PhD student in Cognitive Science at UC San Diego, working with Judy Fan and David Kirsh. I study the cognitive tools that underlie our ability to create new things. As well as shed light on how we convert ideas into physical stuff, I hope that my research can be used to engineer more organic relationships between humans and creative machines.',
    },
    {
        id: 3,
        name: 'Felix Binder',
        imgsrc: 'https://picsum.photos/id/33/600/600',
        major: 'Cognitive Science',
        year:'PhD',
        contact_email:'',
        contact_site:'',
        short_bio:'',
    },
    // {
    //     id: 4,
    //     name: 'Robert Kaufman',
    //     imgsrc: 'https://picsum.photos/id/73/600/600',
    //     major: 'Cognitive Science',
    //     year:'PhD',
    //     contact_email:'??@ucsd.edu',
    //     contact_site:'??',
    //     short_bio:'I',
    // },
    // {
    //     id: 5,
    //     name: 'Abigale Bruce',
    //     imgsrc: 'https://picsum.photos/id/93/600/600',
    //     major: 'Cognitive Science',
    //     year:'PhD',
    //     contact_email:'??@ucsd.edu',
    //     contact_site:'??',
    //     short_bio:'I',
    // },
    // {
    //     id: 6,
    //     name: 'Damian Atkinson',
    //     imgsrc: 'https://picsum.photos/id/39/600/600',
    //     major: 'Cognitive Science',
    //     year:'PhD',
    //     contact_email:'??@ucsd.edu',
    //     contact_site:'??',
    //     short_bio:'I',
    // },
    // {
    //     id: 7,
    //     name: 'Tia Connors',
    //     imgsrc:  'https://picsum.photos/id/30/600/600',
    //     major: 'Cognitive Science',
    //     year:'PhD',
    //     contact_email:'??@ucsd.edu',
    //     contact_site:'??',
    //     short_bio:'I',
    // },
]

export const Ungradinfo = [
    {
        id: 1,
        name: 'Natalia Menendez',
        imgsrc: 'https://picsum.photos/id/24/600/600',
        major: 'Cognitive Science',
        year:'B.S',
        contact_email:'??@ucsd.edu',
        contact_site:'??',
        short_bio:'I',
    },
]  

  
  