/*For course card used on Home page*/
import React, { useState }from 'react';
import { BrowserRouter as Router, Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import FontStyle from '../../FontStyles';
import { Container, Row, Col } from 'react-bootstrap';

import Dropdown from 'react-bootstrap/Dropdown';

import {UngradCourse, GradCourse} from '../../content/Courseinfo';

const Styles = styled.div`
    .dropdown-toggle{
        border: 0.5px solid #DCDCDC;
        background-color: #fff !important;
        color: #000 !important;
    }
    // .button-section{
    //     display: flex;
    //     justify-content: flex-end;
    //     // margin-left: 70%;
    // }
    
    a:active{
        background-color: #B30009;
    }

    .button-section .btn-primary{
        border: none;
        background-color: #B30009 !important;
        color: #fff !important;
    }
    .button-section a{
        color: #fff !important;
    }
    .researchcard{
        display: flex;
        flex-direction: row;
    }

    .button-section{
        position:absolute;
        bottom:0rem;
        right: 5%;
    }
`;

function GradCourseID(targetID) {
    return Object.values(GradCourse).filter(selectedItem =>
        selectedItem.id == targetID
    );
}

function UngradCourseID(targetID) {
    return Object.values(UngradCourse).filter(selectedItem =>
        selectedItem.id == targetID
    );
}



function CardCourseHome(){
    const [currentCourse, setcurrentCourse] = useState(UngradCourse[0]); 
    const [currentCourseLevel, setcurrentCourseLevel] = useState("undergrad"); 


    const HandleClick = (e) => {
        e.preventDefault();
        
        const currentCourseID = parseInt(e.target.id);
        if (currentCourseID < 5){
            setcurrentCourse(UngradCourseID(currentCourseID)[0]);
        }else{
            setcurrentCourse(GradCourseID(currentCourseID-UngradCourse.length)[0]);
            setcurrentCourseLevel("grad");
        }
    }

    // const HandleCourseRedirect = (e) => {
    //     const [currentCourseLink, setcurrentCourseLink] = useState("/teaching/undergrad/2/COGS187A"); 
    //     const currentCourseID = parseInt(e.target.id);
    //     useEffect(() => {
    //         if (currentCourseID < 5){
    //             props.match.params.level === "grad"
    //             // setcourseInfo(GradCourseID(courseInfo.id)[0]);
    //             // console.log(GradCourse(courseInfo.id));
    //         }else{
    //             // setcourseInfo(UngradCourseID(courseInfo.id)[0])
    //         }
    //     },[]);
    // }

  return(
    <React.Fragment>
    <Styles>
        <FontStyle>
            <Container>
            <Row>
                <Col xs={6} md={6}>
                    <h3>Teaching</h3>
                </Col>
                <Col xs={6} md={6}>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                        Select a Course
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item id= "1" onClick={HandleClick}>COGS100: Cyborgs Now and in the Future </Dropdown.Item>
                            <Dropdown.Item id= "2" onClick={HandleClick}>COGS187A: Usability and Info. Architecture</Dropdown.Item>
                            <Dropdown.Item id= "3" onClick={HandleClick}>COGS187B: Practicum in Pro Web Design</Dropdown.Item>
                            <Dropdown.Item id= "4" onClick={HandleClick}>COGS199: Independent Study</Dropdown.Item>
                            <Dropdown.Item id= "5" onClick={HandleClick}>COGS260: 4E Cognition: Embedded, Embodied, Enactive and Extended</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <br/>
                </Col>
            
            </Row>
            
        
            <Row>  
                <Col xs={12} md={6}>    
                    <img src={currentCourse.thumbnailimg} alt={currentCourse.coursecode} width="100%"/>     
                </Col>   
                <Col xs={12} md={5}>
                    <div className="coursecard" >
                        <h4>{currentCourse.title}</h4>
                        <h5>{currentCourse.time} | {currentCourse.location}</h5>
                        <p style={{'marginBottom':"10%"}}>{currentCourse.description}</p>
                        
                        <div className="button-section">
                        <Button>
                            <Link to={"/teaching/" + currentCourseLevel + "/" + currentCourse.id + "/" + currentCourse.coursecode}>
                                ... More
                            </Link>
                        </Button>
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>
      </FontStyle>
    </Styles>
    </React.Fragment>
  );
}

export default CardCourseHome;
