import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FontStyle from '../FontStyles';
import styled from 'styled-components';

const Styles = styled.div`
.breadcrumb{
    margin-top: 0.5rem;
    background-color: rgba(255, 255, 255, 0) !important;
}
.breadcrumb-item a{
    color: #FF3A3A;
    // color: #B30009;
}
a:hover{
    text-decoration:none;
}

`;

function BreadCrumb(props){
    return(
        <Styles>
            <FontStyle>
                <Breadcrumb>
                    <Breadcrumb.Item href={props.link1}>Home</Breadcrumb.Item>
                    <Breadcrumb.Item href={props.link2}>
                        {props.secondlevel}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.current}</Breadcrumb.Item>
                </Breadcrumb>
            </FontStyle>
        </Styles>
    );
}

export default BreadCrumb;